import React, {
    useContext,
    useEffect
} from "react";
import {
    Link,
    Redirect
} from "react-router-dom";
import {
    Alert
} from "react-bootstrap";
import {
    AuthenticationPage,
    AuthenticationPageColumn,
    AuthenticationPageFooter
} from "@zandor300/backoffice-framework";

import Loading from "../../components/Loading";
import AuthenticatedUserContext from "../../context/AuthenticatedUserContext";
import Helmet from "../../components/Helmet";
import OrganisationsContext from "../../context/OrganisationsContext";

import logo from "../../img/logo.svg";
import Copyright from "./components/Copyright";

function Organisations() {
    const organisationsContext = useContext(OrganisationsContext);
    const userContext = useContext(AuthenticatedUserContext);

    useEffect(() => {
        organisationsContext.refreshOrganisations();
    }, [organisationsContext.refreshOrganisations]);

    return (
        <AuthenticationPage>
            <Helmet title="Organisaties"/>
            <AuthenticationPageColumn>
                <img src={ logo } alt="DRVR app" className="login-logo"/>
                <h3 className="mt-0 text-center text-muted mb-4">Organisaties</h3>
                <div className="text-center mb-3">
                    <p className="mb-0">
                        Welkom {userContext.user.name}
                    </p>
                    <button onClick={userContext.logoutFunction} className="btn btn-link btn-sm">
                        Uitloggen
                    </button>
                </div>
                { organisationsContext.error ? (
                    <Alert variant="danger">{ organisationsContext.error }</Alert>
                ) : organisationsContext.organisations === null ? (
                    <div>
                        <Loading/>
                    </div>
                ) : (
                    <div className="list-group">
                        { organisationsContext.organisations.length === 1 && (
                            <Redirect to={"/organisation/" + organisationsContext.organisations[0].codeName + "/dashboard"}/>
                        )}
                        { organisationsContext.organisations.map((organisation) => (
                            <Link
                                to={ "/organisation/" + organisation.codeName + "/dashboard" }
                                key={ organisation.id }
                                className="list-group-item list-group-item-action"
                            >
                                { organisation.name }
                            </Link>
                        ))}
                    </div>
                )}
            </AuthenticationPageColumn>
            <AuthenticationPageFooter>
                <Copyright/>
            </AuthenticationPageFooter>
        </AuthenticationPage>
    );
}

export default Organisations;
