import React, {
    useEffect,
    useState
} from "react";
import axios from "axios";
import {
    Alert
} from "react-bootstrap";
import Skeleton from "react-loading-skeleton";

import DetailOverviewRow from "../../../../components/DetailOverviewRow";
import DateFormatter from "../../../../components/DateFormatter";
import EventDetailOverviewTicketTypeCountTable from "./components/EventDetailOverviewTicketTypeCountTable";

function EventDetailOverview({ event }) {
    const [eventTicketTypes, setEventTicketTypes] = useState(null);
    const [error, setError] = useState(null);

    useEffect(() => {
        if(!event) {
            return;
        }
        async function fetchEventTicketTypes() {
            try {
                const response = await axios.post("/getEventTicketTypes", { eventId: event.id });
                setEventTicketTypes(response.data.eventTicketTypes);
            } catch(requestError) {
                console.error(requestError);
                setError("Er ging iets fout bij het ophalen van de ticket types. Probeer het later opnieuw.");
            }
        }
        fetchEventTicketTypes();
    }, [event]);

    return (
        <React.Fragment>
            <div className="row">
                <div className="col-lg-4">
                    <div className="card mb-3">
                        <div className="card-body">
                            <h5 className="card-title">Uitnodigingen</h5>
                            { error ? (
                                <Alert variant="danger">{ error }</Alert>
                            ) : !eventTicketTypes ? (
                                <Skeleton count={3}/>
                            ) : eventTicketTypes.length === 0 ? (
                                <p className="text-muted">Er zijn nog geen ticket types aangemaakt.</p>
                            ) : (
                                <EventDetailOverviewTicketTypeCountTable
                                    ticketTypeCounts={ eventTicketTypes.map((ticketType) => ({
                                        eventTicketType: ticketType,
                                        count: ticketType.amountOfInvites
                                    })) }
                                />
                            )}
                        </div>
                    </div>
                </div>
                <div className="col-lg-4">
                    <div className="card mb-3">
                        <div className="card-body">
                            <h5 className="card-title">Deelnemers</h5>
                            { error ? (
                                <Alert variant="danger">{ error }</Alert>
                            ) : !eventTicketTypes ? (
                                <Skeleton count={ 3 }/>
                            ) : eventTicketTypes.length === 0 ? (
                                <p className="text-muted">Er zijn nog geen ticket types aangemaakt.</p>
                            ) : (
                                <EventDetailOverviewTicketTypeCountTable
                                    ticketTypeCounts={ eventTicketTypes.map((ticketType) => ({
                                        eventTicketType: ticketType,
                                        count: ticketType.amountOfParticipants
                                    })) }
                                />
                            )}
                        </div>
                    </div>
                </div>
                <div className="col-lg-4">
                    <div className="card mb-3">
                        <div className="card-body">
                            <h5 className="card-title">Gescande tickets</h5>
                            { error ? (
                                <Alert variant="danger">{ error }</Alert>
                            ) : !eventTicketTypes ? (
                                <Skeleton count={ 3 }/>
                            ) : eventTicketTypes.length === 0 ? (
                                <p className="text-muted">Er zijn nog geen ticket types aangemaakt.</p>
                            ) : (
                                <EventDetailOverviewTicketTypeCountTable
                                    ticketTypeCounts={ eventTicketTypes.map((ticketType) => ({
                                        eventTicketType: ticketType,
                                        count: ticketType.amountOfScannedParticipants
                                    })) }
                                />
                            )}
                        </div>
                    </div>
                </div>
                <div className="col-lg-4">
                    <div className="card mb-3">
                        <div className="card-body">
                            <h5 className="card-title">Niet-gescande tickets</h5>
                            { error ? (
                                <Alert variant="danger">{ error }</Alert>
                            ) : !eventTicketTypes ? (
                                <Skeleton count={ 3 }/>
                            ) : eventTicketTypes.length === 0 ? (
                                <p className="text-muted">Er zijn nog geen ticket types aangemaakt.</p>
                            ) : (
                                <EventDetailOverviewTicketTypeCountTable
                                    ticketTypeCounts={ eventTicketTypes.map((ticketType) => ({
                                        eventTicketType: ticketType,
                                        count: ticketType.amountOfNotScannedParticipants
                                    })) }
                                />
                            )}
                        </div>
                    </div>
                </div>
            </div>
            <div className="card mb-3">
                <div className="card-body">
                    <table className="table table-borderless mb-0">
                        <tbody>
                            <DetailOverviewRow title="Naam">
                                { event ? (
                                    event.name
                                ) : <Skeleton width={200}/>}
                            </DetailOverviewRow>
                            <DetailOverviewRow title="Zichtbaarheid">
                                { event ? (
                                    event.publiclyVisible ? (
                                        <React.Fragment>
                                            <i className="fas fa-eye fa-fw text-success mr-2"/>
                                            <span className="text-muted">Publiekelijk zichtbaar voor iedereen</span>
                                        </React.Fragment>
                                    ) : (
                                        <React.Fragment>
                                            <i className="fas fa-eye-slash fa-fw text-danger mr-2"/>
                                            <span className="text-muted">Alleen zichtbaar voor deelnemers</span>
                                        </React.Fragment>
                                    )
                                ) : <Skeleton width={200}/>}
                            </DetailOverviewRow>
                            <DetailOverviewRow title="Startdatum">
                                { event ? (
                                    !event.startDate ? (
                                        <i className="text-muted">Niet ingesteld</i>
                                    ) : (
                                        <DateFormatter date={ event.startDate } format="DD-MM-YYYY HH:mm"/>
                                    )
                                ) : <Skeleton width={200}/>}
                            </DetailOverviewRow>
                            <DetailOverviewRow title="Einddatum">
                                { event ? (
                                    !event.endDate ? (
                                        <i className="text-muted">Niet ingesteld</i>
                                    ) : (
                                        <DateFormatter date={ event.endDate } format="DD-MM-YYYY HH:mm"/>
                                    )
                                ) : <Skeleton width={200}/>}
                            </DetailOverviewRow>
                            <DetailOverviewRow title="Beschrijving">
                                { event ? (
                                    event.description
                                ) : <Skeleton count={3}/>}
                            </DetailOverviewRow>
                            <DetailOverviewRow title="Web pagina URL">
                                { event ? (
                                    event.infoWebpage
                                ) : <Skeleton width={200}/>}
                            </DetailOverviewRow>
                        </tbody>
                    </table>
                </div>
            </div>
        </React.Fragment>
    );
}

export default React.memo(EventDetailOverview);
