import React, {
    useCallback,
    useEffect,
    useState
} from "react";
import axios from "axios";
import {
    Route,
    withRouter,
    Switch
} from "react-router-dom";
import {
    Alert
} from "react-bootstrap";
import {
    TabBar,
    TabBarItem,
    Title
} from "@zandor300/backoffice-framework";
import Skeleton from "react-loading-skeleton";

import Helmet from "../../../../components/Helmet";
import Error404 from "../../Error404";
import {
    withOrganisationsContext
} from "../../../../context/OrganisationsContext";
import {
    BackButton
} from "../../../../context/NavigationContext";

import EventDetailOverview from "./EventDetailOverview";
import EventDetailInvites from "./EventDetailInvites";
import EventDetailParticipants from "./EventDetailParticipants";
import EventDetailRides from "./EventDetailRides";
import EventDetailDesign from "./EventDetailDesign";
import EventDetailEdit from "./EventDetailEdit";

function EventDetail({ match, organisationsContext }) {
    const [event, setEvent] = useState(null);
    const [error, setError] = useState(null);
    const [errorCode, setErrorCode] = useState(null);

    const refreshEvent = useCallback(() => {
        if(!organisationsContext.currentOrganisation) {
            return;
        }
        axios.post("/getEvent", { eventId: match.params.eventId, organisationId: organisationsContext.currentOrganisation.id })
            .then((response) => {
                if(response.data.valid) {
                    setEvent(response.data.event);
                } else {
                    setError("Er ging iets fout. Probeer het later opnieuw. (" + response.data.error + ")");
                    setErrorCode(response.data.error);
                }
            })
            .catch((error) => {
                console.error(error);
                setError("Er ging iets fout. Probeer het later opnieuw.");
            });
    }, [match.params.eventId, organisationsContext.currentOrganisation]);
    useEffect(() => {
        refreshEvent();
    }, [refreshEvent]);

    const baseUrl = match.url;

    if(errorCode === "DOESNT_EXIST") {
        return (
            <Error404/>
        );
    }
    const childProps = {
        event,
        setEvent,
        refreshEvent,
        baseUrl
    };
    return (
        <React.Fragment>
            <Helmet title={ event?.name ?? "Evenement" }/>
            <BackButton to="/events" title="Terug naar evenementen"/>
            <Title preTitle="Detail" noBottom>
                { error ? (
                    "Error"
                ) : !event ? (
                    <Skeleton width={ 200 }/>
                ) : (
                    event.name
                )}
            </Title>
            { error ? (
                <Alert variant="danger">{ error }</Alert>
            ) : !event ? (
                <TabBar>
                    { [...new Array(5)].map((value, index) => (
                        <div className="my-3 mx-3" key={ index }>
                            <Skeleton width={ 75 }/>
                        </div>
                    ))}
                </TabBar>
            ) : (
                <React.Fragment>
                    <TabBar>
                        <TabBarItem to={ baseUrl }>
                            Overzicht
                        </TabBarItem>
                        <TabBarItem to={ baseUrl + "/invites" }>
                            Uitnodigingen
                        </TabBarItem>
                        <TabBarItem to={ baseUrl + "/participants" }>
                            Deelnemers
                        </TabBarItem>
                        <TabBarItem to={ baseUrl + "/rides" }>
                            Ritten
                        </TabBarItem>
                        <TabBarItem to={ baseUrl + "/design" }>
                            Design
                        </TabBarItem>
                        <TabBarItem to={ baseUrl + "/edit" }>
                            Bewerken
                        </TabBarItem>
                    </TabBar>

                    <Switch>
                        <Route path={ baseUrl } exact>
                            <EventDetailOverview { ...childProps }/>
                        </Route>
                        <Route path={ baseUrl + "/invites" } exact>
                            <EventDetailInvites { ...childProps }/>
                        </Route>
                        <Route path={ baseUrl + "/participants" } exact>
                            <EventDetailParticipants { ...childProps }/>
                        </Route>
                        <Route path={ baseUrl + "/rides" } exact>
                            <EventDetailRides { ...childProps }/>
                        </Route>
                        <Route path={ baseUrl + "/design" } exact>
                            <EventDetailDesign { ...childProps }/>
                        </Route>
                        <Route path={ baseUrl + "/edit" } exact>
                            <EventDetailEdit { ...childProps }/>
                        </Route>
                    </Switch>
                </React.Fragment>
            )}
        </React.Fragment>
    );
}

export default withRouter(withOrganisationsContext(React.memo(EventDetail)));
