import React, {
    useCallback,
    useState
} from "react";
import axios from "axios";
import {
    Alert,
    Button,
    Modal,
    Spinner
} from "react-bootstrap";
import PropTypes from "prop-types";

import Loading from "../../../../../components/Loading";

function ResendAllTicketConfirmationsModal({ show, handleClose, event, participants, handleSuccess }) {
    const [sending, setSending] = useState(false);
    const [error, setError] = useState(null);

    const resendTicketConfirmation = useCallback(async () => {
        setSending(true);
        setError(null);
        try {
            await axios.post("/resendAllTicketConfirmations", {
                eventId: event.id
            });
            handleSuccess();
            handleClose();
        } catch(requestError) {
            console.error(requestError);
            const errorCode = requestError.response?.data?.error;
            switch(errorCode) {
            case "EMAIL_FAILURE":
                setError("Er ging iets fout bij het versturen van de e-mails. Er zijn geen emails verstuurd. Probeer het later opnieuw.");
                break;
            default:
                setError("Er ging iets fout. Probeer het later opnieuw.");
                break;
            }
            setSending(false);
        }
    }, [event, handleSuccess, handleClose]);

    const onShow = useCallback(() => {
        setError(null);
        setSending(false);
    }, []);

    return (
        <Modal show={ show } onHide={ handleClose } onShow={ onShow }>
            <Modal.Header closeButton>
                <Modal.Title>Ticketbevestiging opnieuw versturen aan alle deelnemers</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                { error && (
                    <Alert variant="danger">{ error }</Alert>
                )}
                { !participants && !event ? (
                    <Loading/>
                ) : (
                    <React.Fragment>
                        <p>
                            Weet je zeker dat je de ticketbevestiging opnieuw wilt versturen aan alle <b>{ participants?.length }{" "}
                            deelnemers</b> van { event.name }?
                        </p>
                        <p className="mb-0 text-muted">
                            <i>
                                Alle deelnemers zullen een e-mail ontvangen met daarin een uitleg van en link naar hun ticket
                                voor het { event.name } evenement.
                                Het kan enkele minuten duren voordat alle e-mails zijn verstuurd.
                            </i>
                        </p>
                    </React.Fragment>
                ) }
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={ handleClose } disabled={ sending }>
                    Annuleer
                </Button>
                <Button variant="primary" onClick={ resendTicketConfirmation } disabled={ sending }>
                    { sending && (
                        <Spinner animation="border" variant="dark" size="sm" className="mr-2"/>
                    )}
                    { participants?.length } email{ participants?.length === 1 ? "" : "s" } versturen
                </Button>
            </Modal.Footer>
        </Modal>
    );
}
ResendAllTicketConfirmationsModal.propTypes = {
    show: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,
    event: PropTypes.object,
    participants: PropTypes.array,
    handleSuccess: PropTypes.func.isRequired
};

export default React.memo(ResendAllTicketConfirmationsModal);
