import React, {
    useMemo
} from "react";
import {
    Button
} from "react-bootstrap";

export const rideMarkerTypes = [
    {
        id: "speedcamera",
        name: "Flitspaal",
        icon: (<i className="fas fa-cctv fa-fw"/>)
    },
    {
        id: "gasstation",
        name: "Tankstation",
        icon: (<i className="fas fa-gas-pump fa-fw"/>)
    },
    {
        id: "placeofinterest",
        name: "Bezienswaardigheid",
        icon: (<i className="fas fa-map-marker-alt fa-fw"/>)
    },
    {
        id: "restaurant",
        name: "Restaurant",
        icon: (<i className="fas fa-utensils fa-fw"/>)
    },
    {
        id: "hotel",
        name: "Hotel",
        icon: (<i className="fas fa-bed-alt fa-fw"/>)
    }
];

function RideMarkerIcon({ marker }) {
    if(!marker) {
        return null;
    }
    const type = rideMarkerTypes.find((type) => type.id === marker.type);
    if(!type) {
        return (
            <i className="fas fa-question fa-fw"/>
        );
    }
    return type.icon;
}

function RideMarkerCard({ marker, onMouseEnter, onMouseLeave, onClickDelete, onClickEdit }) {
    const onEdit = useMemo(() => {
        return () => {
            onClickEdit(marker);
        };
    }, [marker, onClickEdit]);
    const onDelete = useMemo(() => {
        return () => {
            onClickDelete(marker);
        };
    }, [marker, onClickDelete]);
    return (
        <div
            className="card mb-2 card-hover"
            onMouseEnter={ () => onMouseEnter(marker) }
            onMouseLeave={ () => onMouseLeave(marker) }
        >
            <div className="card-body">
                <div className="d-flex flex-row align-items-center">
                    <div className="flex-grow-1 d-flex flex-row align-items-center">
                        <div className="mr-3">
                            <RideMarkerIcon marker={ marker }/>
                        </div>
                        <div className="d-flex flex-column w-100">
                            <div>
                                { marker.title }
                            </div>
                            { marker.new ? (
                                <div>
                                    <span className="badge badge-success badge-pill">
                                        Nieuw
                                    </span>
                                </div>
                            ) : marker.changed && (
                                <div>
                                    <span className="badge badge-secondary badge-pill">
                                        Bewerkt
                                    </span>
                                </div>
                            )}
                        </div>
                    </div>
                    <div>
                        <Button variant="link" size="sm" className="text-secondary" onClick={ onEdit }>
                            <i className="fas fa-pen"/>
                        </Button>
                        <Button variant="link" size="sm" className="text-danger" onClick={ onDelete }>
                            <i className="fas fa-trash-alt"/>
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default React.memo(RideMarkerCard);
