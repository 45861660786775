import React, {
    useCallback,
    useContext,
    useEffect,
    useState
} from "react";
import axios from "axios";
import {
    Alert,
    Button,
    Modal
} from "react-bootstrap";
import {
    v4 as uuid
} from "uuid";

import OrganisationsContext from "../../../../../context/OrganisationsContext";
import Loading from "../../../../../components/Loading";

const sessionUUID = uuid();

function GeocodingSearchModal({ ride, show, handleClose, onSelect }) {
    const organisationsContext = useContext(OrganisationsContext);
    const [query, setQuery] = useState("");
    const [error, setError] = useState(null);
    const [suggestions, setSuggestions] = useState(null);
    const [suggestionsLoading, setSuggestionsLoading] = useState(false);
    const [resultLoading, setResultLoading] = useState(false);

    const loadSuggestions = useCallback(async (searchQuery, signal) => {
        if(searchQuery.length <= 0) {
            setSuggestions(null);
            return;
        }
        setError(null);
        setSuggestionsLoading(true);
        try {
            const response = await axios.post("/getGeocodeSearchResults", {
                organisationId: organisationsContext.currentOrganisation.id,
                rideId: ride.id,
                sessionUUID,
                query: searchQuery
            }, {
                signal
            });
            setSuggestions(response.data.results?.suggestions);
            setSuggestionsLoading(false);
        } catch(requestError) {
            if (requestError.code === "ERR_CANCELED") {
                return;
            }
            console.error(requestError);
            setError("Er ging iets fout. Probeer het later opnieuw.");
            setSuggestionsLoading(false);
        }
    }, [ride, organisationsContext.currentOrganisation]);
    const onResultSelect = useCallback(async (mapboxId) => {
        setResultLoading(true);
        setError(null);
        try {
            const response = await axios.post("/getGeocodeSearchResult", {
                organisationId: organisationsContext.currentOrganisation.id,
                rideId: ride.id,
                sessionUUID,
                resultId: mapboxId
            });
            if(!response.data.result?.features || response.data.result.features.length === 0) {
                setError("Er ging iets fout. Probeer het later opnieuw.");
                return;
            }
            const feature = response.data.result.features[0];
            let latitude = feature.properties.coordinates.latitude;
            let longitude = feature.properties.coordinates.longitude;
            if(feature.properties.coordinates.routable_points && feature.properties.coordinates.routable_points.length > 0) {
                latitude = feature.properties.coordinates.routable_points[0].latitude;
                longitude = feature.properties.coordinates.routable_points[0].longitude;
            }
            onSelect({
                name: feature.properties.name,
                latitude,
                longitude
            });
            handleClose();
        } catch(requestError) {
            console.error(requestError);
            setError("Er ging iets fout. Probeer het later opnieuw.");
        } finally {
            setResultLoading(false);
        }
    }, [onSelect, handleClose]);

    useEffect(() => {
        const abortController = new AbortController();
        const delayDebounceFn = setTimeout(() => {
            loadSuggestions(query, abortController.signal);
        }, 500);
        return () => {
            clearTimeout(delayDebounceFn);
            abortController.abort();
        };
    }, [query, loadSuggestions]);

    const onShow = useCallback(() => {
        setQuery("");
        setError(null);
        setSuggestions(null);
        setSuggestionsLoading(false);
        setResultLoading(false);
    }, []);
    const onChange = useCallback((event) => {
        setQuery(event.target.value);
    }, [loadSuggestions]);

    return (
        <Modal
            show={ show }
            onShow={ onShow }
            onHide={ handleClose }
            backdropClassName="waypoint-delete-backdrop"
        >
            <Modal.Header closeButton>
                <Modal.Title>Adres zoeken</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                { error && (
                    <Alert variant="danger">{ error }</Alert>
                )}
                <input
                    type="text"
                    className="form-control mb-3"
                    placeholder="Zoek een adres"
                    value={ query }
                    onChange={ onChange }
                    disabled={ resultLoading }
                />
                { !suggestions && suggestionsLoading && (
                    <Loading/>
                )}
                { suggestions && suggestions.length === 0 && (
                    <div className="text-center">
                        <h3>
                            <i className="fas fa-map-marker-question"/>
                        </h3>
                        <h5 className="m-0">
                            Geen resultaten gevonden.
                        </h5>
                    </div>
                )}
                { suggestions && suggestions.map((suggestion, index) => {
                    const onClick = () => onResultSelect(suggestion.mapbox_id);
                    return (
                        <div
                            key={ index }
                            className="card card-hover mb-1"
                            onClick={ (suggestionsLoading || resultLoading) ? undefined : onClick }
                            style={{
                                opacity: (suggestionsLoading || resultLoading) ? 0.5 : 1,
                                cursor: (suggestionsLoading || resultLoading) ? "not-allowed" : "pointer"
                            }}
                        >
                            <div className="card-body px-3 py-2">
                                <div className="d-flex flex-row align-items-center">
                                    <div className="mr-3">
                                        <i className="fas fa-map-marker-alt fa-fw"/>
                                    </div>
                                    <div>
                                        <b>{ suggestion.name }</b>
                                        <div className="text-muted">
                                            { suggestion.full_address || suggestion.place_formatted }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    );
                })}
            </Modal.Body>
            <Modal.Footer>
                <Button
                    variant="secondary"
                    onClick={ handleClose }
                    disabled={ resultLoading }
                >
                    Annuleer
                </Button>
            </Modal.Footer>
        </Modal>
    );
}

export default React.memo(GeocodingSearchModal);
