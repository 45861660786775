import React, {
    useMemo,
    useState
} from "react";
import axios from "axios";
import {
    Link
} from "react-router-dom";
import {
    Alert,
    Button,
    ButtonGroup
} from "react-bootstrap";
import {
    AuthenticationPage,
    AuthenticationPageColumn,
    AuthenticationPageFooter
} from "@zandor300/backoffice-framework";

import Helmet from "../../components/Helmet";
import Copyright from "./components/Copyright";

import logo from "../../img/logo.svg";

function ForgotPassword() {
    const [email, setEmail] = useState("");
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(null);
    const [loading, setLoading] = useState(false);

    const onSubmit = useMemo(() => {
        return (event) => {
            event.preventDefault();
            setLoading(true);
            axios.post("/initiateForgotPassword", { email })
                .then((response) => {
                    if(response.data.valid) {
                        setSuccess(`Als er een account bestaat met ${email} als email adres, dan is er een link verstuurd waarmee het wachtwoord opnieuw ingesteld kan worden.`);
                    } else {
                        setError("Er ging iets fout. Probeer het later opnieuw.");
                    }
                })
                .catch(() => {
                    setError("Er ging iets fout. Probeer het later opnieuw.");
                })
                .finally(() => {
                    setLoading(false);
                });
        };
    }, [email]);

    return (
        <AuthenticationPage>
            <Helmet title="Wachtwoord vergeten"/>
            <AuthenticationPageColumn>
                <img src={ logo } alt="DRVR app" className="login-logo"/>
                <h3 className="mt-0 text-center text-muted mb-4">Wachtwoord vergeten</h3>
                { success !== null && (
                    <Alert variant="success">{ success }</Alert>
                )}
                { error !== null && (
                    <Alert variant="danger">{ error }</Alert>
                )}
                <form onSubmit={ onSubmit }>
                    <div className="form-group">
                        <input
                            type="email"
                            className="form-control"
                            id="email"
                            placeholder="Email"
                            disabled={ loading }
                            value={ email }
                            onChange={(event) => setEmail(event.target.value) }
                        />
                    </div>
                    <ButtonGroup className="align-items-center justify-content-end w-100">
                        <div className="clearfix">
                            <Button type="submit" variant="primary" disabled={ loading }>
                                Versturen
                            </Button>
                        </div>
                    </ButtonGroup>
                </form>

                <div className="text-center mt-5 mb-3">
                    <Link to="/login" className="text-secondary btn-sm">
                        Terug naar login
                    </Link>
                </div>
            </AuthenticationPageColumn>
            <AuthenticationPageFooter>
                <Copyright/>
            </AuthenticationPageFooter>
        </AuthenticationPage>
    );
}

export default React.memo(ForgotPassword);
