import React, {
    useContext
} from "react";
import {
    Link
} from "react-router-dom";
import {
    NavDropdown
} from "react-bootstrap";
import {
    Navbar
} from "@zandor300/backoffice-framework";

import AuthenticatedUserContext from "../../context/AuthenticatedUserContext";
import OrganisationsContext from "../../context/OrganisationsContext";
import NavigationContext from "../../context/NavigationContext";

function BackofficeNavbar() {
    const organisationsContext = useContext(OrganisationsContext);
    const authenticatedUserContext = useContext(AuthenticatedUserContext);
    const navigationContext = useContext(NavigationContext);

    const user = authenticatedUserContext.user;

    return (
        <Navbar
            leftChildren={
                <React.Fragment>
                    { navigationContext.backLink && (
                        <li className="nav-item">
                            <Link to={ navigationContext.backLink } className="nav-link" style={{ paddingLeft: 0 }}>
                                <i className="fas fa-chevron-circle-left mr-2"/>
                                { navigationContext.backTitle ? navigationContext.backTitle : "Terug" }
                            </Link>
                        </li>
                    )}
                </React.Fragment>
            }
        >
            { organisationsContext.organisations !== null && organisationsContext.organisations.length > 0 && (
                <NavDropdown
                    title={ organisationsContext.currentOrganisation ? organisationsContext.currentOrganisation.name : "" }
                    id="organisation"
                    align="right"
                >
                    { organisationsContext.organisations.map((organisation) => (
                        <Link
                            key={ organisation.id }
                            to={ "/organisation/" + organisation.codeName + "/dashboard" }
                            className= { "dropdown-item" + (organisationsContext.currentOrganisation && organisation.id === organisationsContext.currentOrganisation.id ? " active" : "")}
                        >
                            { organisation.name }
                        </Link>
                    ))}
                    <NavDropdown.Divider />
                    <Link to={ "/organisations" } className="dropdown-item">
                        Alle organisaties
                    </Link>
                </NavDropdown>
            )}
            <NavDropdown title={ user && user.name } id="user">
                <NavDropdown.Item onClick={ authenticatedUserContext.logoutFunction }>
                    Uitloggen
                </NavDropdown.Item>
            </NavDropdown>
        </Navbar>
    );
}

export default React.memo(BackofficeNavbar);
