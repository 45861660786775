import React, {
    useEffect,
    useState
} from "react";
import axios from "axios";
import {
    Alert,
    Button
} from "react-bootstrap";
import {
    ErrorBoundary
} from "@sentry/react";

import RideEditor from "./editor/RideEditor";
import Loading from "../../../../components/Loading";
import RideEditorModal from "./editor/RideEditorModal";
import {
    withOrganisationsContext
} from "../../../../context/OrganisationsContext";
import DetailOverviewRow from "../../../../components/DetailOverviewRow";
import DateFormatter from "../../../../components/DateFormatter";

function RideDetailOverview({ ride, setRide, organisationsContext }) {
    const [showRideMarkerEditModal, setShowRideMarkerEditModal] = useState(false);
    const [rideGeoJSON, setRideGeoJSON] = useState(null);

    useEffect(() => {
        if(!ride || !organisationsContext.currentOrganisation) {
            return;
        }
        axios.post("/getRideGeoJSON", { rideId: ride.id, organisationId: organisationsContext.currentOrganisation.id })
            .then((response) => {
                setRideGeoJSON(response.data.geojson);
            })
            .catch(() => {

            });
    }, [ride, organisationsContext]);

    if(!ride) {
        return (
            <Loading/>
        );
    }
    return (
        <React.Fragment>
            <div className="card mb-3">
                <div className="card-body">
                    <table className="table table-borderless mb-0">
                        <tbody>
                            <DetailOverviewRow title="Naam">
                                { ride.name }
                            </DetailOverviewRow>
                            <DetailOverviewRow title="Startdatum">
                                { ride.startDate ? (
                                    <DateFormatter date={ ride.startDate }/>
                                ) : (
                                    <i className="text-muted">Niet ingesteld</i>
                                )}
                            </DetailOverviewRow>
                            <DetailOverviewRow title="Einddatum">
                                { ride.endDate ? (
                                    <DateFormatter date={ ride.endDate }/>
                                ) : (
                                    <i className="text-muted">Niet ingesteld</i>
                                )}
                            </DetailOverviewRow>
                        </tbody>
                    </table>
                </div>
            </div>

            <RideEditorModal
                show={ showRideMarkerEditModal }
                handleClose={ () => setShowRideMarkerEditModal(false) }
                ride={ ride }
                setRide={ setRide }
                rideGeoJSON={ rideGeoJSON }
            />
            <Button
                variant="primary"
                className="mb-2"
                onClick={ () => setShowRideMarkerEditModal(true) }
            >
                Route bewerken
            </Button>
            { !ride.waypoints ? (
                <Loading/>
            ) : (
                <ErrorBoundary
                    fallback={
                        <Alert variant="danger">
                            Er is een fout opgetreden bij het laden van de kaart.
                        </Alert>
                    }
                >
                    <RideEditor
                        waypoints={ ride.waypoints }
                        geoJSON={ rideGeoJSON }
                        style={{ height: "600px" }}
                        markersDraggable={ false }
                    />
                </ErrorBoundary>
            )}
        </React.Fragment>
    );
}

export default withOrganisationsContext(React.memo(RideDetailOverview));
