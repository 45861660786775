import React from "react";
import {
    Button
} from "react-bootstrap";

function RideEditorSidebarOverlay({ children, onCancel }) {
    return (
        <div className="mapbox-modal-sidebar-overlay">
            <div className="d-flex flex-column">
                { children }
                <div className="mt-4">
                    <Button
                        variant="light"
                        onClick={ onCancel }
                    >
                        Annuleren
                    </Button>
                </div>
            </div>
        </div>
    );
}

export default React.memo(RideEditorSidebarOverlay);
