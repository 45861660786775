import React from "react";

import Loading from "../../../../components/Loading";
import DesignSettings from "../../../../components/design/DesignSettings";

function EventDetailDesign({ event, refreshEvent }) {
    if(!event) {
        return (
            <Loading/>
        );
    }
    return (
        <DesignSettings
            title={ event.name }
            description={ event.description }
            banner={ event.banner }
            logo={ event.logo }
            uploadParameters={{
                eventId: event.id,
            }}
            bannerUploadUrl="/uploadEventBanner"
            logoUploadUrl="/uploadEventLogo"
            onUpdate={ refreshEvent }
        />
    );
}

export default React.memo(EventDetailDesign);
