import React from "react";
import axios from "axios";
import {
    Alert,
    Button,
    Modal,
    Spinner
} from "react-bootstrap";

import Loading from "../../../../../components/Loading";

class RemoveInviteModal extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            updating: false,
            error: null
        };
        this.removeInvite = this.removeInvite.bind(this);
        this.closeModal = this.closeModal.bind(this);
    }

    removeInvite() {
        this.setState({ updating: true, error: null });
        axios.post("/removeEventInvite", { inviteId: this.props.invite.id })
            .then((response) => {
                this.setState({ updating: false });
                if(response.data.valid) {
                    this.props.handleSuccess();
                    this.closeModal();
                } else {
                    this.setState({ updating: false, error: "Er ging iets fout. Probeer het later opnieuw." });
                }
            })
            .catch(() => {
                this.setState({ updating: false, error: "Er ging iets fout. Probeer het later opnieuw." });
            });
    }

    closeModal() {
        this.props.handleClose();
    }

    render() {
        const {
            show,
            invite
        } = this.props;
        const {
            updating,
            error
        } = this.state;
        return (
            <Modal show={ show } onHide={ this.closeModal }>
                <Modal.Header closeButton>
                    <Modal.Title>Uitnodiging verwijderen</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    { !invite ? (
                        <Loading/>
                    ) : (
                        <React.Fragment>
                            { error && (
                                <Alert variant="danger">{ error }</Alert>
                            )}
                            <p>
                                De uitnodigingslink zal ongeldig worden en de gebruiker zal een nieuwe link nodig hebben
                                om zich alsnog te registeren bij dit evenement. Deze actie kan niet teruggedraait
                                worden.
                            </p>
                            <p className="mb-0">
                                Je gaat een uitnodiging voor het volgende email adres verwijderen:{" "}
                                <b>{ invite.email }</b>
                            </p>
                        </React.Fragment>
                    )}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={ this.closeModal } disabled={ updating }>
                        Annuleren
                    </Button>
                    <Button variant="danger" onClick={ this.removeInvite } disabled={ updating }>
                        { updating && (
                            <Spinner animation="border" variant="light" size="sm" className="mr-2"/>
                        )}
                        Uitnodiging verwijderen
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    }

}

export default RemoveInviteModal;
