import React from "react";
import {
    PageContainer,
    PageContent,
    PageFooter
} from "@zandor300/backoffice-framework";
import BackofficeVersion from "./BackofficeVersion";

function BackofficePageContainer({ children }) {
    return (
        <PageContainer>
            <PageContent>
                { children }
            </PageContent>
            <PageFooter>
                <BackofficeVersion/>
            </PageFooter>
        </PageContainer>
    );
}

export default React.memo(BackofficePageContainer);
