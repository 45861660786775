import React from "react";

function DetailOverviewRow({ title, children, small, titleVerticalCenter, className, hideOnPrint = false }) {
    let containerClassNames = [];
    if(className) {
        containerClassNames.push(className);
    }
    if(hideOnPrint) {
        containerClassNames.push("d-print-none");
    }

    let headerClassNames = ["detail-header-cell"];
    if(small) {
        headerClassNames.push("py-1");
    }
    if(titleVerticalCenter) {
        headerClassNames.push("va-middle");
    }

    let contentClassNames = [];
    if(small) {
        contentClassNames.push("py-1");
    }

    return (
        <tr className={ containerClassNames.join(" ") }>
            <th className={ headerClassNames.join(" ") }>
                { title }
            </th>
            <td className={ contentClassNames.join(" ") }>
                { children }
            </td>
        </tr>
    );
}

export default React.memo(DetailOverviewRow);
