function getEventErrorMessage(errorCode) {
    switch(errorCode) {
    case "NAME_TOO_SHORT": return "Naam is te kort.";
    case "INVALID_INFOWEBPAGE": return "Ongeldige link.";
    case "STARTDATE_BEFORE_ENDDATE": return "Start datum staat ingesteld na de eind datum.";
    case "DATES_NOT_BOTH_FILLED_IN": return "Als een van de datums ingevuld is moeten beide ingevuld zijn.";
    default: return "Er ging iets fout. Probeer het later opnieuw. (" + errorCode + ")";
    }
}

export default getEventErrorMessage;
