import React from "react";
import axios from "axios";
import {
    Alert,
    Button,
    Modal,
    Spinner
} from "react-bootstrap";

import Loading from "../../../../../components/Loading";

class StartRideModal extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            error: null
        };
        this.onStartRide = this.onStartRide.bind(this);
    }

    onStartRide() {
        this.setState({ loading: true, error: null });
        axios.post("/startRide", { rideId: this.props.ride.id })
            .then((response) => {
                if(response.data.valid) {
                    this.props.rideUpdated(response.data.ride);
                    this.props.handleClose();
                } else {
                    this.setState({ error: "Er ging iets fout. Probeer het later opnieuw. (" + response.data.error + ")" });
                }
            })
            .catch(() => {
                this.setState({ error: "Er ging iets fout. Probeer het later opnieuw." });
            })
            .finally(() => {
                this.setState({ loading: false });
            });
    }

    render() {
        const {
            loading,
            error
        } = this.state;
        const {
            show,
            handleClose,
            ride
        } = this.props;
        return (
            <Modal show={ show } onHide={ handleClose }>
                <Modal.Header closeButton>
                    <Modal.Title>Rit starten</Modal.Title>
                </Modal.Header>
                { (loading || !ride) ? (
                    <Modal.Body>
                        <Loading/>
                    </Modal.Body>
                ) : (!ride.startDate || !ride.endDate) ? (
                    <React.Fragment>
                        <Modal.Body>
                            <h4 className="text-danger">
                                Rit starten niet mogelijk.
                            </h4>
                            <p>
                                Start of einddatum van de rit is niet ingesteld. Dit is vereist om een rit te mogen starten.
                            </p>
                            <p className="mb-0">
                                Stel de start en einddatum in en probeer het opnieuw.
                            </p>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={ handleClose }>
                                Sluiten
                            </Button>
                        </Modal.Footer>
                    </React.Fragment>
                ) : (
                    <React.Fragment>
                        <Modal.Body>
                            { error && (
                                <Alert variant="danger">{ error }</Alert>
                            )}
                            <p>
                                Iedere deelnemer zal een melding ontvangen dat het evenement gaat beginnen.
                            </p>
                            <p className="mb-0">
                                Weet je zeker dat je de rit wilt starten? Dit is niet terug te draaien.
                            </p>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={ handleClose } disabled={ loading }>
                                Annuleer
                            </Button>
                            <Button variant="primary" type="submit" onClick={ this.onStartRide } disabled={ loading }>
                                { loading && (
                                    <Spinner animation="border" variant="dark" size="sm" className="mr-2"/>
                                )}
                                Rit starten
                            </Button>
                        </Modal.Footer>
                    </React.Fragment>
                )}
            </Modal>
        );
    }
}

export default StartRideModal;
