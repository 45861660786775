import React from "react";
import {
    Helmet as ReactHelmet
} from "react-helmet";

function Helmet({ title = null }) {
    return (
        <ReactHelmet>
            <title>
                { title === null ? "DRVR Backoffice" : (title + " - DRVR Backoffice") }
            </title>
        </ReactHelmet>
    );
}

export default React.memo(Helmet);
