import React, {
    useCallback,
    useContext
} from "react";

import OrganisationsContext from "../../../../context/OrganisationsContext";
import Loading from "../../../../components/Loading";
import DesignSettings from "../../../../components/design/DesignSettings";

function SettingsDesign() {
    const organisationsContext = useContext(OrganisationsContext);

    const onUpdate = useCallback(() => {
        organisationsContext.refreshOrganisations(true);
    }, [organisationsContext.refreshOrganisations]);

    if(!organisationsContext.currentOrganisation) {
        return (
            <Loading/>
        );
    }
    return (
        <DesignSettings
            title={ organisationsContext.currentOrganisation.name }
            description={ organisationsContext.currentOrganisation.description }
            banner={ organisationsContext.currentOrganisation.banner }
            logo={ organisationsContext.currentOrganisation.logo }
            uploadParameters={{
                organisationId: organisationsContext.currentOrganisation.id,
            }}
            bannerUploadUrl="/uploadOrganisationBanner"
            logoUploadUrl="/uploadOrganisationLogo"
            onUpdate={ onUpdate }
        />
    );
}

export default React.memo(SettingsDesign);
