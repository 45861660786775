import React, {
    useContext
} from "react";
import {
    TabBar,
    TabBarItem,
    Title
} from "@zandor300/backoffice-framework";

import OrganisationsContext from "../../../context/OrganisationsContext";
import {Redirect, Route, Switch} from "react-router-dom";
import SettingsDesign from "./tabs/SettingsDesign";

function Settings() {
    const organisationsContext = useContext(OrganisationsContext);
    if(!organisationsContext.currentOrganisation) {
        return null;
    }
    const baseUrl = "/organisation/" + organisationsContext.currentOrganisation.codeName;
    return (
        <React.Fragment>
            <Title preTitle="Overzicht" noBottom>
                Instellingen
            </Title>
            <TabBar>
                <TabBarItem
                    to={ baseUrl + "/settings/design" }
                >
                    Design
                </TabBarItem>
            </TabBar>
            <Switch>
                <Route path={ baseUrl + "/settings/design" }>
                    <SettingsDesign/>
                </Route>
                <Route path={ baseUrl + "/settings" }>
                    <Redirect to={ baseUrl + "/settings/design" }/>
                </Route>
            </Switch>
        </React.Fragment>
    );
}

export default React.memo(Settings);
