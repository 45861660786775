import React from "react";
import axios from "axios";
import {
    Alert,
    Button,
    Spinner
} from "react-bootstrap";

import RideForm from "./components/RideForm";
import {
    withOrganisationsContext
} from "../../../../context/OrganisationsContext";
import getEventErrorMessage from "../../events/getEventErrorMessage";
import rideDateInputToAPI from "../rideDateInputToAPI";

class RideDetailEdit extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            ride: null,
            error: null,
            success: false,
            loading: false
        };
        this.onChangeRide = this.onChangeRide.bind(this);
        this.setRide = this.setRide.bind(this);
    }

    componentDidMount() {
        this.setState({ ride: this.props.ride });
    }

    componentDidUpdate(prevProps, _prevState, _snapshot) {
        if(prevProps.ride !== this.props.ride) {
            this.setState({ ride: this.props.ride });
        }
    }

    setRide() {
        this.setState({ loading: true, error: null, success: false });
        const {
            name,
            startDate,
            endDate,
            onlyVisibleWhenStarted,
            autoStart,
            autoStop,
        } = this.state.ride;
        const currentOrganisation = this.props.organisationsContext.currentOrganisation;
        axios.post("/setRide", {
            rideId: this.props.ride.id,
            organisationId: currentOrganisation.id,
            name,
            startDate: rideDateInputToAPI(startDate),
            endDate: rideDateInputToAPI(endDate),
            onlyVisibleWhenStarted: onlyVisibleWhenStarted ? 1 : 0,
            autoStart: autoStart ? 1 : 0,
            autoStop: autoStop ? 1 : 0,
        })
            .then((response) => {
                if(response.data.valid) {
                    this.props.setRide(response.data.ride);
                    this.setState({ success: true });
                } else {
                    this.setState({ error: getEventErrorMessage(response.data.error) });
                }
            })
            .catch((error) => {
                console.error(error);
                this.setState({ error: "Er ging iets fout. Probeer het later opnieuw." });
            })
            .finally(() => {
                window.scroll({ top: 0, behavior: "smooth" });
                this.setState({ loading: false });
            });
    }

    onChangeRide(changes) {
        this.setState((prevState) => {
            return { ride: { ...prevState.ride, ...changes }, success: false };
        });
    }

    render() {
        const { ride, error, loading, success } = this.state;
        return (
            <React.Fragment>
                { success && (
                    <Alert variant="success">
                        Wijzigingen zijn opgeslagen.
                    </Alert>
                )}
                { error && (
                    <Alert variant="danger">
                        { error }
                    </Alert>
                )}
                <RideForm
                    ride={ ride }
                    setRide={ this.onChangeRide }
                    loading={ loading }
                />
                <Button
                    variant="primary"
                    className="float-right"
                    disabled={ loading }
                    onClick={ this.setRide }
                >
                    { loading && (
                        <Spinner animation="border" variant="dark" size="sm" className="mr-2"/>
                    )}
                    Opslaan
                </Button>
            </React.Fragment>
        );
    }
}

export default withOrganisationsContext(RideDetailEdit);
