import React from "react";
import axios from "axios";
import {
    withRouter
} from "react-router-dom";
import {
    Alert,
    Button,
    Spinner
} from "react-bootstrap";
import {
    Title
} from "@zandor300/backoffice-framework";

import {
    withOrganisationsContext
} from "../../../context/OrganisationsContext";
import Helmet from "../../../components/Helmet";
import EventForm from "./detail/components/EventForm";
import getEventErrorMessage from "./getEventErrorMessage";

class EventAdd extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            error: null,
            loading: false,

            name: "",
            description: "",
            infoWebpage: "",
            startDate: "",
            endDate: "",
            chatAudioAutoplayMaxDistance: 2000,
            publiclyVisible: false
        };
        this.onChangeEvent = this.onChangeEvent.bind(this);
        this.addEvent = this.addEvent.bind(this);
    }

    addEvent() {
        this.setState({ loading: true, error: null });
        const {
            name,
            description,
            infoWebpage,
            startDate,
            endDate,
            chatAudioAutoplayMaxDistance,
            publiclyVisible
        } = this.state;
        const currentOrganisation = this.props.organisationsContext.currentOrganisation;
        axios.post("/addEvent", {
            organisationId: currentOrganisation.id,
            name,
            description,
            infoWebpage,
            startDate,
            endDate,
            chatAudioAutoplayMaxDistance,
            publiclyVisible: publiclyVisible ? 1 : 0
        })
            .then((response) => {
                if(response.data.valid) {
                    const event = response.data.event;
                    const baseUrl = "/organisation/" + currentOrganisation.codeName;
                    const eventUrl = baseUrl + "/events/" + event.id;
                    this.props.history.push(eventUrl);
                } else {
                    this.setState({ loading: false, error: getEventErrorMessage(response.data.error) });
                    window.scroll({ top: 0, behavior: "smooth" });
                }
            })
            .catch((error) => {
                console.error(error);
                window.scroll({ top: 0, behavior: "smooth" });
                this.setState({ loading: false, error: "Er ging iets fout. Probeer het later opnieuw." });
            });
    }

    onChangeEvent(changes) {
        this.setState({ ...changes, success: false });
    }

    render() {
        const { error, loading } = this.state;
        return (
            <React.Fragment>
                <Helmet title="Evenement toevoegen"/>
                <Title preTitle="Overzicht">
                    Evenement toevoegen
                </Title>
                { error && (
                    <Alert variant="danger">
                        { error }
                    </Alert>
                )}
                <EventForm
                    event={ this.state }
                    setEvent={ this.onChangeEvent }
                    loading={ loading }
                />
                <Button
                    variant="primary"
                    className="float-right"
                    disabled={ loading }
                    onClick={ this.addEvent }
                >
                    { loading && (
                        <Spinner animation="border" variant="dark" size="sm" className="mr-2"/>
                    )}
                    Opslaan
                </Button>
            </React.Fragment>
        );
    }
}

export default withOrganisationsContext(withRouter(EventAdd));
