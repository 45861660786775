import React, {
    useMemo
} from "react";

function numberFormatter({ number, maximumFractionDigits = 3 }) {
    const formatter = useMemo(() => {
        return new Intl.NumberFormat("nl-NL", { maximumFractionDigits });
    }, [maximumFractionDigits]);
    return formatter.format(number);
}

export default React.memo(numberFormatter);
