import React, {
    useCallback
} from "react";
import moment from "moment";

function DateTimeInput({ date, onChange, loading, id, dateLabel, timeLabel, className }) {
    const onChangeInternal = useCallback((event, type) => {
        const dateSplit = date.split(" ");
        let currentDate, currentTime;
        if(dateSplit.length === 2) {
            currentDate = dateSplit[0];
            currentTime = dateSplit[1];
        } else {
            currentDate = moment().format("YYYY-MM-DD");
            currentTime = "00:00:00";
        }
        if(type === "date") {
            currentDate = event.target.value;
        } else if(type === "time") {
            currentTime = event.target.value;
        }
        onChange(`${currentDate} ${currentTime}`);
    }, [onChange, date]);

    return (
        <div className={ "row" + (className ? ` ${className}` : "") }>
            <div className="col-md-6 col-lg-4 col-xl-3">
                <div className="form-group mb-md-0">
                    <label htmlFor={`${id}Date`}>{ dateLabel }</label>
                    <input
                        type="date"
                        className="form-control"
                        id={`${id}Date`}
                        disabled={ loading }
                        value={ date?.split(" ")[0] }
                        onChange={ (event) => {
                            onChangeInternal(event, "date");
                        }}
                    />
                </div>
            </div>
            <div className="col-md-6 col-lg-4 col-xl-3">
                <div className="form-group mb-0">
                    <label htmlFor={`${id}Time`}>{ timeLabel }</label>
                    <input
                        type="time"
                        className="form-control"
                        id={`${id}Time`}
                        disabled={ loading }
                        value={ date?.split(" ")[1] }
                        onChange={ (event) => {
                            onChangeInternal(event, "time");
                        }}
                    />
                </div>
            </div>
        </div>
    );
}

export default React.memo(DateTimeInput);
