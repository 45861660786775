import React, {
    useContext,
    useEffect,
    useMemo,
    useState
} from "react";
import {
    withRouter
} from "react-router-dom";

import onHistoryChange from "../hooks/onHistoryChange";
import OrganisationsContext from "./OrganisationsContext";

const NavigationContext = React.createContext(null);

function NavigationManagerInternal({ children, history }) {
    const [backLink, setBackLink] = useState(null);
    const [backTitle, setBackTitle] = useState(null);
    const setBackNavigation = useMemo(() => {
        return (link, title) => {
            setBackLink(link);
            setBackTitle(title);
        };
    }, []);
    onHistoryChange(history, () => {
        window.scrollTo(0, 0);
        setBackNavigation(null, null);
    });
    return (
        <NavigationContext.Provider value={{
            backLink,
            backTitle,
            setBackNavigation
        }}>
            { children }
        </NavigationContext.Provider>
    );
}

export function BackButton({ to = null, title = null }) {
    const navigationContext = useContext(NavigationContext);
    const organisationsContext = useContext(OrganisationsContext);
    useEffect(() => {
        if(!organisationsContext || !organisationsContext.currentOrganisation) {
            navigationContext.setBackNavigation(null, null);
            return;
        }
        const url = `/organisation/${organisationsContext.currentOrganisation.codeName}${to}`;
        navigationContext.setBackNavigation(url, title);
    }, [navigationContext, to, title]);
    return null;
}

export const NavigationManager = withRouter(NavigationManagerInternal);

export default NavigationContext;
