import React, {
    useCallback,
    useEffect,
    useMemo,
    useState
} from "react";
import {
    Alert,
    Button,
    Modal,
    Spinner
} from "react-bootstrap";
import {
    v4 as uuidv4
} from "uuid";
import {
    useLocalStorage
} from "@zandor300/backoffice-framework";

import {
    isDevelopment
} from "../../../../../index";
import RideEditor from "./RideEditor";
import Loading from "../../../../../components/Loading";
import DeleteWaypointModal from "../modal/DeleteWaypointModal";
import CloseEditorUnsavedModal from "../modal/CloseEditorUnsavedModal";
import RideEditSidebarRoute from "./components/sidebar/RideEditSidebarRoute";
import RideEditModalModeSwitcher from "../components/RideEditModalModeSwitcher";
import RideEditSidebarMarkers from "./components/sidebar/RideEditSidebarMarkers";
import EditMarkerModal from "../modal/EditMarkerModal";
import DeleteMarkerModal from "../modal/DeleteMarkerModal";
import MapboxStyleDropdown from "./components/MapboxStyleDropdown";
import RideEditorSidebarOverlay from "./components/RideEditorSidebarOverlay";
import useRideEditorStateManager from "./hooks/useRideEditorStateManager";
import GeocodingSearchModal from "../modal/GeocodingSearchModal";

function RideEditorModal({ show, handleClose, ride, setRide, rideGeoJSON }) {
    const {
        undo,
        redo,
        canUndo,
        canRedo,
        waypoints,
        markers,
        geoJSON,
        hasChanges,
        geoJSONError,
        loading,
        saving,
        success,
        setWaypoints,
        setMarkers,
        save
    } = useRideEditorStateManager({
        ride: show ? ride : null,
        setRide,
        rideGeoJSON: show ? rideGeoJSON : null
    });
    const [showDebug, setShowDebug] = useLocalStorage("showDebugMenu", isDevelopment);

    const [mode, setMode] = useState("route");
    const [addMarkerMode, setAddMarkerMode] = useState(false);

    const [hidden, setHidden] = useState(true);
    const [hoveredWaypointId, setHoveredWaypointId] = useState(null);
    const [hoveredMarkerId, setHoveredMarkerId] = useState(null);

    const [showDeleteWaypointModal, setShowDeleteWaypointModal] = useState(false);
    const [waypointToDelete, setWaypointToDelete] = useState(null);

    const [showDeleteMarkerModal, setShowDeleteMarkerModal] = useState(false);
    const [markerToDelete, setMarkerToDelete] = useState(null);

    const [showEditMarkerModal, setShowEditMarkerModal] = useState(false);
    const [editMarkerModalMarker, setEditMarkerModalMarker] = useState(null);

    const [showGeocodingSearchModal, setShowGeocodingSearchModal] = useState(false);

    const [showCloseEditorUnsavedModal, setShowCloseEditorUnsavedModal] = useState(false);

    const onWaypointDragEnd = useMemo(() => {
        return (waypointId, longitude, latitude) => {
            setWaypoints((prevWaypoints) => {
                return prevWaypoints.map((prevWaypoint) => {
                    if(prevWaypoint.id === waypointId) {
                        return {
                            ...prevWaypoint,
                            longitude,
                            latitude,
                            changed: true
                        };
                    }
                    return prevWaypoint;
                });
            });
        };
    }, []);
    const onMarkerDragEnd = useMemo(() => {
        return (markerId, longitude, latitude) => {
            setMarkers((prevMarkers) => {
                return prevMarkers.map((prevMarker) => {
                    if(prevMarker.id === markerId) {
                        return {
                            ...prevMarker,
                            longitude,
                            latitude,
                            changed: true
                        };
                    }
                    return prevMarker;
                });
            });
        };
    }, []);

    const onWaypointRename = useMemo(() => {
        return (waypointId, name) => {
            setWaypoints((prevWaypoints) => {
                return prevWaypoints.map((prevWaypoint) => {
                    if(prevWaypoint.id === waypointId) {
                        return {
                            ...prevWaypoint,
                            name,
                            changed: true
                        };
                    }
                    return prevWaypoint;
                });
            });
        };
    }, []);
    const addWaypoint = useCallback((longitude, latitude, name = undefined) => {
        setWaypoints((prevWaypoints) => {
            return [...prevWaypoints, {
                id: uuidv4(),
                longitude,
                latitude,
                name,
                changed: true,
                new: true
            }];
        });
    }, []);
    const addMarker = useCallback((longitude, latitude, title = "") => {
        setEditMarkerModalMarker({
            id: uuidv4(),
            longitude,
            latitude,
            type: "",
            title,
            description: "",
            triggerRadius: 200,
            bypassMute: false,
            changed: true,
            new: true
        });
        setShowEditMarkerModal(true);
    }, []);
    const onMapClick = useMemo(() => {
        return (longitude, latitude) => {
            if(!addMarkerMode) {
                return;
            }

            if(mode === "route") {
                addWaypoint(longitude, latitude);
            } else {
                addMarker(longitude, latitude);
            }

            setAddMarkerMode(false);
        };
    }, [addMarkerMode, mode, addWaypoint, addMarker]);

    const onClickAddMarker = useMemo(() => {
        return () => {
            setAddMarkerMode(!addMarkerMode);
        };
    }, [addMarkerMode]);

    useEffect(() => {
        if(!show) {
            return;
        }
        setHidden(false);
    }, [show]);

    const onHidden = useMemo(() => {
        return () => {
            setHidden(true);
        };
    }, []);
    const onMouseEnterWaypoint = useMemo(() => {
        return (waypoint) => {
            setHoveredWaypointId(waypoint.id);
        };
    }, []);
    const onMouseEnterMarker = useMemo(() => {
        return (marker) => {
            setHoveredMarkerId(marker.id);
        };
    }, []);
    const onMouseLeave = useMemo(() => {
        return () => {
            setHoveredWaypointId(null);
            setHoveredMarkerId(null);
        };
    }, []);

    const handleShowDeleteWaypointModal = useMemo(() => {
        return (waypoint) => {
            setWaypointToDelete(waypoint);
            setShowDeleteWaypointModal(true);
        };
    }, []);
    const handleDeleteWaypointModalClose = useMemo(() => {
        return () => {
            setShowDeleteWaypointModal(false);
        };
    }, []);
    const onClickDeleteWaypoint = useMemo(() => {
        return () => {
            setShowDeleteWaypointModal(false);
            setWaypoints((prevWaypoints) => {
                return prevWaypoints.filter((waypoint) => {
                    return waypoint !== waypointToDelete;
                });
            });
        };
    }, [waypointToDelete]);

    const handleShowDeleteMarkerModal = useMemo(() => {
        return (marker) => {
            setMarkerToDelete(marker);
            setShowDeleteMarkerModal(true);
        };
    }, []);
    const handleDeleteMarkerModalClose = useMemo(() => {
        return () => {
            setShowDeleteMarkerModal(false);
        };
    }, []);
    const onClickDeleteMarker = useMemo(() => {
        return () => {
            setShowDeleteMarkerModal(false);
            setMarkers((prevMarkers) => {
                return prevMarkers.filter((marker) => {
                    return marker !== markerToDelete;
                });
            });
        };
    }, [markerToDelete]);

    const handleShowEditMarkerModal = useMemo(() => {
        return (marker) => {
            setEditMarkerModalMarker(marker);
            setShowEditMarkerModal(true);
        };
    }, []);
    const handleEditMarkerModalClose = useMemo(() => {
        return () => {
            setShowEditMarkerModal(false);
        };
    }, []);
    const handleEditMarkerModalSave = useMemo(() => {
        return (marker) => {
            setMarkers((prevMarkers) => {
                let found = false;
                let newMarkers = prevMarkers.map((prevMarker) => {
                    if(prevMarker.id === marker.id) {
                        found = true;
                        return {
                            ...marker,
                            changed: true
                        };
                    }
                    return prevMarker;
                });
                if(!found) {
                    return [...newMarkers, marker];
                }
                return newMarkers;
            });
            setShowEditMarkerModal(false);
        };
    }, []);

    const handleShowGeocodingSearchModal = useCallback(() => {
        setShowGeocodingSearchModal(true);
    }, []);
    const handleGeocodingSearchModalClose = useCallback(() => {
        setShowGeocodingSearchModal(false);
    }, []);
    const handleGeocodingSearchModalOnSelect = useCallback(({ longitude, latitude, name }) => {
        if(mode === "route") {
            addWaypoint(longitude, latitude, name);
        } else {
            addMarker(longitude, latitude, name);
        }
    }, [addWaypoint]);

    const internalHandleClose = useMemo(() => {
        return () => {
            if(hasChanges) {
                setShowCloseEditorUnsavedModal(true);
            } else {
                handleClose();
            }
        };
    }, [handleClose, hasChanges]);
    const handleCloseEditorUnsavedModalClose = useMemo(() => {
        return () => {
            setShowCloseEditorUnsavedModal(false);
        };
    }, []);
    const handleCloseUnsaved = useMemo(() => {
        return () => {
            setShowCloseEditorUnsavedModal(false);
            handleClose();
        };
    }, [handleClose]);

    return (
        <React.Fragment>
            <CloseEditorUnsavedModal
                show={ showCloseEditorUnsavedModal }
                handleClose={ handleCloseEditorUnsavedModalClose }
                onCloseUnsaved={ handleCloseUnsaved }
            />
            <DeleteWaypointModal
                show={ showDeleteWaypointModal }
                handleClose={ handleDeleteWaypointModalClose }
                onDelete={ onClickDeleteWaypoint }
            />
            <DeleteMarkerModal
                show={ showDeleteMarkerModal }
                handleClose={ handleDeleteMarkerModalClose }
                onDelete={ onClickDeleteMarker }
            />
            <EditMarkerModal
                show={ showEditMarkerModal }
                handleClose={ handleEditMarkerModalClose }
                marker={ editMarkerModalMarker }
                onSave={ handleEditMarkerModalSave }
            />
            <GeocodingSearchModal
                ride={ ride }
                show={ showGeocodingSearchModal }
                handleClose={ handleGeocodingSearchModalClose }
                onSelect={ handleGeocodingSearchModalOnSelect }
            />
            <Modal
                className="ride-editor-modal"
                size="fullscreen"
                show={ show }
                onHide={ internalHandleClose }
                onExited={ onHidden }
            >
                <Modal.Header closeButton>
                    <div className="d-flex align-items-center w-100">
                        <Modal.Title className="flex-grow-1">
                            <div className="pretitle">
                                Rit bewerken
                            </div>
                            <div className="title">
                                { ride.name }
                            </div>
                        </Modal.Title>
                        <div>
                            { hasChanges && (
                                <div className="text-danger ml-2">
                                    Niet-opgeslagen wijzigingen!
                                </div>
                            )}
                        </div>
                        <div className="d-flex flex-row pl-3">
                            <Button variant="primary" onClick={ save } disabled={ loading || saving || !hasChanges }>
                                { saving && (
                                    <Spinner animation="border" variant="dark" size="sm" className="mr-2"/>
                                )}
                                <i className="fa-solid fa-floppy-disk fa-fw mr-2"/>
                                Opslaan
                            </Button>
                            <Button variant="light" onClick={ undo } disabled={ loading || saving || !canUndo } className="ml-2">
                                <i className="fa-solid fa-undo fa-fw mr-2"/>
                                Ongedaan maken
                            </Button>
                            <Button variant="light" onClick={ redo } disabled={ loading || saving || !canRedo } className="ml-2">
                                <i className="fa-solid fa-redo fa-fw mr-2"/>
                                Opnieuw toepassen
                            </Button>
                            { isDevelopment && (
                                <Button variant="light" onClick={ () => setShowDebug(!showDebug) } className="ml-2">
                                    <i className="fa-solid fa-bug fa-fw mr-2"/>
                                    Debug
                                </Button>
                            )}
                            <MapboxStyleDropdown className="ml-2"/>
                        </div>
                    </div>
                </Modal.Header>
                <Modal.Body className="p-0 flex-grow-1 d-flex">
                    { waypoints && !hidden && (
                        <div className="d-flex flex-row w-100 flex-grow-1">
                            { (geoJSONError || success) && (
                                <div className="mapbox-error-overlay">
                                    <div className="error-container">
                                        { geoJSONError ? (
                                            <Alert variant="danger">{ geoJSONError }</Alert>
                                        ) : success && (
                                            <Alert variant="success">{ success }</Alert>
                                        )}
                                    </div>
                                </div>
                            )}
                            { (loading || saving) && (
                                <div className="mapbox-loading-overlay">
                                    <div>
                                        <Loading/>
                                    </div>
                                </div>
                            )}
                            <div className="mapbox-modal-sidebar">
                                { addMarkerMode && (
                                    <RideEditorSidebarOverlay onCancel={ () => setAddMarkerMode(false) }>
                                        Selecteer een punt op de kaart.
                                    </RideEditorSidebarOverlay>
                                )}
                                <div className="mapbox-modal-sidebar-content">
                                    <RideEditModalModeSwitcher
                                        mode={ mode }
                                        setMode={ setMode }
                                        disabled={ addMarkerMode }
                                    />
                                    <div className="mapbox-modal-sidebar-scroll">
                                        { mode === "route" ? (
                                            <RideEditSidebarRoute
                                                waypoints={ waypoints }
                                                editGeoJSON={ geoJSON }
                                                onMouseEnter={ onMouseEnterWaypoint }
                                                onMouseLeave={ onMouseLeave }
                                                handleShowDeleteWaypointModal={ handleShowDeleteWaypointModal }
                                                onWaypointsSorted={ setWaypoints }
                                                onWaypointRename={ onWaypointRename }
                                                onClickAddMarker={ onClickAddMarker }
                                                onClickSearch={ handleShowGeocodingSearchModal }
                                            />
                                        ) : mode === "markers" && (
                                            <RideEditSidebarMarkers
                                                markers={ markers }
                                                onMouseEnter={ onMouseEnterMarker }
                                                onMouseLeave={ onMouseLeave }
                                                handleShowDeleteMarkerModal={ handleShowDeleteMarkerModal }
                                                onClickEdit={ handleShowEditMarkerModal }
                                                onClickAddMarker={ onClickAddMarker }
                                                onClickSearch={ handleShowGeocodingSearchModal }
                                            />
                                        )}
                                    </div>
                                </div>
                            </div>
                            <div className="mapbox-modal-container">
                                <RideEditor
                                    waypoints={ waypoints }
                                    markers={ markers }
                                    geoJSON={ geoJSON }
                                    mode={ mode }
                                    markersDraggable={ !addMarkerMode }
                                    hoveredWaypointId={ hoveredWaypointId }
                                    hoveredMarkerId={ hoveredMarkerId }
                                    onWaypointDragEnd={ onWaypointDragEnd }
                                    onMarkerDragEnd={ onMarkerDragEnd }
                                    onMapClick={ onMapClick }
                                    debug={ showDebug && isDevelopment }
                                />
                                { showDebug && isDevelopment && (
                                    <div className="mapbox-history-debug">
                                        Can Undo: { canUndo ? "true" : "false" }
                                        <br/>
                                        Can Redo: { canRedo ? "true" : "false" }
                                    </div>
                                )}
                            </div>
                        </div>
                    )}
                </Modal.Body>
            </Modal>
        </React.Fragment>
    );
}

export default React.memo(RideEditorModal);
