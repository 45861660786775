import React from "react";
import axios from "axios";
import {
    Alert,
    Button,
    Modal,
    Spinner
} from "react-bootstrap";

import Loading from "../../../../../components/Loading";

class ResetRideModal extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            error: null
        };
        this.onResetRide = this.onResetRide.bind(this);
    }

    onResetRide() {
        this.setState({ loading: true, error: null });
        axios.post("/resetRide", { rideId: this.props.ride.id })
            .then((response) => {
                if(response.data.valid) {
                    this.props.rideUpdated(response.data.ride);
                    this.props.handleClose();
                } else {
                    this.setState({ error: "Er ging iets fout. Probeer het later opnieuw. (" + response.data.error + ")" });
                }
            })
            .catch(() => {
                this.setState({ error: "Er ging iets fout. Probeer het later opnieuw." });
            })
            .finally(() => {
                this.setState({ loading: false });
            });
    }

    render() {
        const {
            loading,
            error
        } = this.state;
        const {
            show,
            handleClose,
            ride
        } = this.props;
        return (
            <Modal show={ show } onHide={ handleClose }>
                <Modal.Header closeButton>
                    <Modal.Title>Rit resetten</Modal.Title>
                </Modal.Header>
                { !ride ? (
                    <Modal.Body>
                        <Loading/>
                    </Modal.Body>
                ) : (
                    <React.Fragment>
                        <Modal.Body>
                            { error && (
                                <Alert variant="danger">{ error }</Alert>
                            )}
                            <p className="mb-0">
                                Deze rit is al afgerond. Als je de rit reset kun je daarna de rit opnieuw starten.
                            </p>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={ handleClose } disabled={ loading }>
                                Annuleer
                            </Button>
                            <Button variant="primary" type="submit" onClick={ this.onResetRide } disabled={ loading }>
                                { loading && (
                                    <Spinner animation="border" variant="dark" size="sm" className="mr-2"/>
                                )}
                                Rit resetten
                            </Button>
                        </Modal.Footer>
                    </React.Fragment>
                )}
            </Modal>
        );
    }
}

export default ResetRideModal;
