import React from "react";

import RideMarkerCard from "../../../components/RideMarkerCard";
import RideWaypointAddCard from "../../../components/RideWaypointAddCard";
import RideEditSidebarMarkersEmptyState from "./RideEditSidebarMarkersEmptyState";

function RideEditSidebarMarkers({ markers, onMouseEnter, onMouseLeave, handleShowDeleteMarkerModal, onClickEdit, onClickAddMarker, onClickSearch }) {
    return (
        <React.Fragment>
            { markers.length === 0 && (
                <RideEditSidebarMarkersEmptyState/>
            )}
            { markers.map((marker) => (
                <RideMarkerCard
                    key={ marker.id }
                    marker={ marker }
                    onMouseEnter={ onMouseEnter }
                    onMouseLeave={ onMouseLeave }
                    onClickDelete={ handleShowDeleteMarkerModal }
                    onClickEdit={ onClickEdit }
                />
            ))}
            <div className="d-flex flex-row align-items-stretch">
                <RideWaypointAddCard
                    onClick={ onClickAddMarker }
                    className="flex-grow-1 mr-1 h-100"
                />
                <RideWaypointAddCard
                    icon="fa-search"
                    onClick={ onClickSearch }
                    className="flex-grow-1 ml-1 h-100"
                />
            </div>
        </React.Fragment>
    );
}

export default React.memo(RideEditSidebarMarkers);
