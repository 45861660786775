import React from "react";
import PropTypes from "prop-types";

import NumberFormatter from "../../../../../components/formatters/NumberFormatter";

function EventDetailOverviewTicketTypeCountTable({ ticketTypeCounts }) {
    return (
        <table className="table table-borderless table-no-padding mb-0">
            <tbody>
                { ticketTypeCounts.map((ticketTypeCount) => (
                    <tr key={ ticketTypeCount.eventTicketType.id }>
                        <td>{ ticketTypeCount.eventTicketType.name }</td>
                        <td className="text-right pl-4">
                            <NumberFormatter number={ ticketTypeCount.count }/>
                        </td>
                    </tr>
                )) }
                <tr>
                    <td><b>Totaal</b></td>
                    <td className="text-right pl-4">
                        <b>
                            <NumberFormatter
                                number={ ticketTypeCounts.reduce((total, ticketTypeCount) => total + ticketTypeCount.count, 0) }
                            />
                        </b>
                    </td>
                </tr>
            </tbody>
        </table>
    );
}

EventDetailOverviewTicketTypeCountTable.propTypes = {
    ticketTypeCounts: PropTypes.arrayOf(PropTypes.shape({
        eventTicketType: PropTypes.object.isRequired,
        count: PropTypes.number.isRequired
    })).isRequired
};

export default React.memo(EventDetailOverviewTicketTypeCountTable);
