import React, {
    createContext,
    useMemo
} from "react";
import {
    useLocalStorage
} from "@zandor300/backoffice-framework";

const MapboxStyleContext = createContext(null);

const styles = [
    {
        id: "map",
        name: "Kaart",
        mapboxStyle: "mapbox://styles/zandor300/clmgd72bx003601qt5q9v9dyz",
        icon: "fa-solid fa-map"
    },
    {
        id: "satellite",
        name: "Satelliet",
        mapboxStyle: "mapbox://styles/zandor300/clu8mctpq011n01qshtvr2e6g",
        icon: "fa-solid fa-earth-europe"
    }
];

export function MapboxStyleManager({ children }) {
    const [styleId, setStyle] = useLocalStorage("mapbox-style", "map");

    const style = useMemo(() => {
        return styles.find((searchingStyle) => searchingStyle.id === styleId);
    }, [styleId]);

    return (
        <MapboxStyleContext.Provider value={{ style, setStyle, styles }}>
            { children }
        </MapboxStyleContext.Provider>
    );
}

export function withMapboxStyleContext(Component) {
    return function contextComponent(props) {
        return (
            <MapboxStyleContext.Consumer>
                {context => <Component {...props} mapboxStyleContext={context} />}
            </MapboxStyleContext.Consumer>
        );
    };
}

export default MapboxStyleContext;
