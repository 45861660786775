import React from "react";
import {
    createRoot
} from "react-dom/client";
import axios from "axios";
import moment from "moment";
import * as Sentry from "@sentry/react";
import {
    stringify
} from "qs";
import "moment/locale/nl";
import mapboxgl from "!mapbox-gl";

import * as serviceWorker from "./serviceWorker";
import App from "./App";

import "./scss/main.scss";

export const isDevelopment = process.env.REACT_APP_ENVIRONMENT === "development";

if(isDevelopment) {
    console.log("Running in development mode. Sentry error reporting is disabled.");
} else {
    Sentry.init({
        dsn: "https://8eedf881082b48ca96eced9fed5d47cf@sentry1.zsnode.com/13",
        autoSessionTracking: true,
        integrations: [
            Sentry.browserTracingIntegration(),
        ],
        tracesSampleRate: 1.0,
        tracePropagationTargets: [
            /^https:\/\/api\.drvr-app\.com/,
        ]
    });
}

axios.defaults.transformRequest = [(data) => {
    data = stringify(data);
    return data;
}];

if(isDevelopment) {
    axios.defaults.baseURL = "/v1/";
} else {
    const DRVR_API_URL = process.env.REACT_APP_DRVR_API_URL ? process.env.REACT_APP_DRVR_API_URL : "api.drvr-app.com";
    axios.defaults.baseURL = "https://" + DRVR_API_URL + "/v1/";
}
axios.defaults.withCredentials = true;

moment.locale("nl");

mapboxgl.accessToken = "pk.eyJ1IjoiemFuZG9yMzAwIiwiYSI6ImNrd2M5M2M5ZDBtdTkybnFrbGlveHFtcHgifQ.HW8hb-RoYiDiNvJ817K-JQ";

const container = document.getElementById("root");
const root = createRoot(container);
root.render(<App/>);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
