import React, {
    useCallback,
    useState
} from "react";
import {
    Button
} from "react-bootstrap";

import UploadImageModal from "../modal/UploadImageModal";
import DesignSettingsExample from "./DesignSettingsExample";

function DesignSettings({
    title,
    description,
    banner,
    logo,
    uploadParameters,
    bannerUploadUrl,
    logoUploadUrl,
    onUpdate
}) {
    const [showUploadModal, setShowUploadModal] = useState(false);
    const [requestUrl, setRequestUrl] = useState(null);

    const onClickUploadBanner = useCallback(() => {
        setShowUploadModal(true);
        setRequestUrl(bannerUploadUrl);
    }, [bannerUploadUrl]);
    const onClickUploadLogo = useCallback(() => {
        setShowUploadModal(true);
        setRequestUrl(logoUploadUrl);
    }, [logoUploadUrl]);

    return (
        <div className="d-flex flex-column flex-lg-row">
            <UploadImageModal
                show={ showUploadModal }
                handleClose={ () => setShowUploadModal(false) }
                requestUrl={ requestUrl }
                requestParameters={ uploadParameters }
                imageUploaded={ onUpdate }
            />
            <div className="flex-grow-1 mr-lg-3">
                <div className="card mb-2">
                    <div className="card-body">
                        <h5 className="card-title">
                            Banner afbeelding
                        </h5>
                        { banner ? (
                            <div>
                                <img
                                    src={ banner.url }
                                    alt="Banner afbeelding"
                                    className="img-fluid rounded mb-3"
                                    style={{
                                        maxHeight: "200px",
                                        objectFit: "cover",
                                    }}
                                />
                            </div>
                        ) : (
                            <p className="card-text">
                                Geen banner afbeelding toegevoegd.
                            </p>
                        )}
                        <Button
                            variant="primary"
                            size="sm"
                            onClick={ onClickUploadBanner}
                        >
                            <i className="fas fa-upload mr-2"/>
                            Afbeelding uploaden
                        </Button>
                    </div>
                </div>
                <div className="card mb-2">
                    <div className="card-body">
                        <h5 className="card-title">
                            Logo
                        </h5>
                        { logo ? (
                            <div
                                className="d-flex justify-content-center p-3 rounded mb-3"
                                style={{ backgroundColor: "#333" }}
                            >
                                <img
                                    src={ logo.url }
                                    alt="Logo"
                                    className="img-fluid mb-3"
                                    style={{
                                        maxHeight: "100px",
                                        objectFit: "cover",
                                    }}
                                />
                            </div>
                        ) : (
                            <p className="card-text">
                                Geen logo toegevoegd.
                            </p>
                        )}
                        <Button
                            variant="primary"
                            size="sm"
                            onClick={ onClickUploadLogo }
                        >
                            <i className="fas fa-upload mr-2"/>
                            Afbeelding uploaden
                        </Button>
                    </div>
                </div>
            </div>
            <div className="event-design-column">
                <DesignSettingsExample
                    title={ title }
                    banner={ banner }
                    logo={ logo }
                    description={ description }
                />
            </div>
        </div>
    );
}

export default React.memo(DesignSettings);
