import React from "react";
import axios from "axios";
import {
    Redirect,
    BrowserRouter as Router,
    Route,
    Switch
} from "react-router-dom";
import {
    CSSTransition
} from "react-transition-group";

import AuthenticatedUserContext from "./context/AuthenticatedUserContext";
import OrganisationsContext, {
    OrganisationsManager
} from "./context/OrganisationsContext";

import PrivateRoute from "./components/PrivateRoute";
import AuthenticationRouter from "./pages/authentication/AuthenticationRouter";
import Organisations from "./pages/authentication/Organisations";
import BackofficeRouter from "./pages/BackofficeRouter";

import logo from "./img/logo.svg";

class App extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showWhiteOverlayOverride: false,
            showInitialLoadingIndicator: false,
            authenticatedUser: {
                user: undefined,
                loginFunction: this.login.bind(this),
                logoutFunction: this.logout.bind(this)
            }
        };
    }

    componentDidMount() {
        setTimeout(() => {
            if(this.state.authenticatedUser.user === undefined) {
                this.setState({ showInitialLoadingIndicator: true });
            }
        }, 100);
        this.getSession();
    }

    setUser(user) {
        this.setState(previousState => {
            return { authenticatedUser: { ...previousState.authenticatedUser, user: user } };
        });
    }

    showWhiteOverlayOverridden(value, stateCheck = () => { return false; }) {
        this.setState({ showWhiteOverlayOverride: value, showInitialLoadingIndicator: false });
        if(value) {
            setTimeout(() => {
                if(stateCheck()) {
                    this.setState({ showInitialLoadingIndicator: true });
                }
            }, 100);
        }
    }

    login(state, onErrorOccurred) {
        const email = state.email;
        const password = state.password;

        this.showWhiteOverlayOverridden(true, () => { return this.state.authenticatedUser.user === null; });
        axios.post("/login", { email: email, password: password, useCookie: true, deviceType: "desktop" })
            .then((response) => {
                if(response.data.valid) {
                    setTimeout(() => {
                        this.setUser(response.data.user);
                    }, 1000);
                } else {
                    setTimeout(() => {
                        if(response.data.error === "INVALID_CREDENTIALS") {
                            onErrorOccurred("Foutieve gebruikersnaam of wachtwoord.");
                        } else {
                            onErrorOccurred("Er ging iets fout. Probeer het later opnieuw.");
                        }
                    }, 1000);
                }
            })
            .catch(() => {
                setTimeout(() => {
                    onErrorOccurred("Er ging iets fout. Probeer het later opnieuw.");
                }, 1000);
            })
            .finally(() => {
                setTimeout(() => {
                    this.showWhiteOverlayOverridden(false);
                }, 1000);
            });
    }

    getSession() {
        axios.get("/getSession")
            .then((response) => {
                if(response.data.valid) {
                    this.setUser(response.data.user);
                } else {
                    this.setUser(null);
                }
            })
            .catch(() => {
                this.setUser(null);
            });
    }

    logout() {
        this.showWhiteOverlayOverridden(true, () => { return this.state.authenticatedUser.user !== null; });
        axios.get("/logout")
            .then((response) => {
                if(response.data.valid) {
                    setTimeout(() => {
                        this.setUser(null);
                    }, 500);
                } else {
                    // TODO: Display pop-over error message.
                }
            })
            .catch(() => {
                // TODO: Display pop-over error message.
            })
            .finally(() => {
                setTimeout(() => {
                    this.showWhiteOverlayOverridden(false);
                }, 500);
            });
    }

    render() {
        return (
            <Router>
                <OrganisationsManager>
                    <OrganisationsContext.Consumer>
                        { organisationContext =>
                            <React.Fragment>
                                <CSSTransition
                                    in={ this.state.authenticatedUser.user === undefined || this.state.showWhiteOverlayOverride || (this.state.authenticatedUser.user && organisationContext.organisations === null) }
                                    timeout={500}
                                    classNames="initial-load-background"
                                    unmountOnExit
                                >
                                    <div style={{
                                        position: "absolute",
                                        top: 0,
                                        height: "100vh",
                                        width: "100%",
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        backgroundColor: "#F9FBFD",
                                        zIndex: 1100
                                    }}>
                                        <CSSTransition
                                            in={ this.state.showInitialLoadingIndicator }
                                            timeout={500}
                                            classNames="initial-load-logo"
                                            appear
                                            mountOnEnter
                                        >
                                            <img src={ logo } alt="DRVR app" style={{ maxWidth: "400px" }}/>
                                        </CSSTransition>
                                    </div>
                                </CSSTransition>

                                { this.state.authenticatedUser.user !== undefined && (
                                    <AuthenticatedUserContext.Provider value={ this.state.authenticatedUser }>
                                        <Switch>

                                            <PrivateRoute
                                                authenticated={ this.state.authenticatedUser.user === null }
                                                path={ ["/login", "/forgot-password"] }
                                                target="/organisations"
                                                component={ AuthenticationRouter }
                                            />

                                            <PrivateRoute
                                                authenticated={ this.state.authenticatedUser.user !== null }
                                                path="/"
                                                target="/login"
                                            >
                                                <Switch>
                                                    <Route path="/organisations" component={ Organisations }/>
                                                    <Route path="/organisation/:organisationCodeName" component={ BackofficeRouter }/>
                                                    <Route path="/">
                                                        <Redirect to="/organisations"/>
                                                    </Route>
                                                </Switch>
                                            </PrivateRoute>

                                        </Switch>
                                    </AuthenticatedUserContext.Provider>
                                )}
                            </React.Fragment>
                        }
                    </OrganisationsContext.Consumer>
                </OrganisationsManager>
            </Router>
        );
    }
}

export default App;
