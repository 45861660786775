import React from "react";

function RideWaypointAddCard({ className, onClick, icon = "fa-plus" }) {
    const cardClassNames = ["card", "card-hover", "pointer-cursor"];
    const iconClassNames = ["fas", icon, "text-success"];

    if(className) {
        cardClassNames.push(className);
    }

    return (
        <div
            className={ cardClassNames.join(" ") }
            onClick={ onClick }
        >
            <div className="card-body text-center py-2">
                <h3 className="m-0">
                    <i className={ iconClassNames.join(" ") }/>
                </h3>
            </div>
        </div>
    );
}

export default React.memo(RideWaypointAddCard);
