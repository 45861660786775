import React from "react";
import moment from "moment";
import {
    OverlayTrigger,
    Tooltip
} from "react-bootstrap";

function DateFormatter({ date, format, message }) {
    const tooltip = (
        <Tooltip id={ "date-" + date }>
            { moment(date).format("LLLL").replaceAll("00:00", "") }
            { message && (
                <React.Fragment>
                    <br/>
                    { message }
                </React.Fragment>
            )}
        </Tooltip>
    );
    return (
        <React.Fragment>
            <OverlayTrigger placement="top" overlay={ tooltip }>
                <span className={ !format ? "d-print-none" : "" }>
                    { format ? moment(date).format(format) : moment(date).calendar() }
                </span>
            </OverlayTrigger>
            { !format && (
                <span className="d-none d-print-inline-block">
                    { moment(date).format("DD-MM-YYYY HH:mm").replaceAll("00:00", "") }
                </span>
            )}
        </React.Fragment>
    );
}

export default React.memo(DateFormatter);
