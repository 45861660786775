import React from "react";
import axios from "axios";
import {
    Alert,
    Button,
    Form,
    Modal,
    Spinner
} from "react-bootstrap";

import Loading from "../../../../../components/Loading";
import PriceFormatter from "../../../../../components/formatters/priceFormatter";

class NewInviteModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            eventTicketTypes: null,

            email: "",
            selectedEventTicketType: 0,

            sending: false,
            loadError: null,
            error: null
        };
        this.onShow = this.onShow.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
    }

    getErrorMessage(error = undefined) {
        switch(error) {
        case "INVALID_EMAIL":
            return "Ongeldig email adres.";
        case "ALREADY_INVITED":
            return "Er bestaat al een uitnodiging voor dit email adres.";
        default:
            return "Er ging iets fout. Probeer het later opnieuw." + (error ? " (" + error + ")" : "");
        }
    }

    getEventTicketTypes() {
        this.setState({ eventTicketTypes: null, loadError: null });
        axios.post("/getEventTicketTypes", { eventId: this.props.eventId })
            .then((response) => {
                this.setState({ eventTicketTypes: response.data.eventTicketTypes });
            })
            .catch((error) => {
                console.error(error);
                this.setState({ loadError: this.getErrorMessage(error) });
            });
    }

    sendInvite() {
        if(this.state.eventTicketTypes.length > 0 && this.state.selectedEventTicketType === 0) {
            this.setState({ error: "Selecteer een ticket type." });
            return;
        }
        const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if(!emailRegex.test(this.state.email)) {
            this.setState({ sending: false, error: this.getErrorMessage("INVALID_EMAIL") });
            return;
        }
        this.setState({ sending: true, error: null });
        axios.post("/sendInvite", {
            eventId: this.props.eventId,
            email: this.state.email,
            eventTicketTypeId: this.state.selectedEventTicketType
        })
            .then((response) => {
                if(response.data.valid) {
                    this.props.handleSuccess();
                    this.props.handleClose();
                } else {
                    const error = response.data.error;
                    this.setState({ error: this.getErrorMessage(error) });
                }
            })
            .catch((error) => {
                console.error(error);
                this.setState({ error: this.getErrorMessage() });
            })
            .finally(() => {
                this.setState({ sending: false });
            });
    }

    onShow() {
        this.setState({
            error: null,
            email: "",
            selectedEventTicketType: 0
        });
        this.getEventTicketTypes();
    }

    onSubmit(event) {
        event.preventDefault();
        this.setState({ error: null });
        this.sendInvite();
    }

    render() {
        const {
            show
        } = this.props;
        const {
            sending,
            email,
            eventTicketTypes,
            selectedEventTicketType,
            error,
            loadError
        } = this.state;
        return (
            <Modal show={ show } onHide={ this.props.handleClose } onShow={ this.onShow }>
                <Modal.Header closeButton>
                    <Modal.Title>Nieuwe uitnodiging</Modal.Title>
                </Modal.Header>
                <Form onSubmit={ this.onSubmit }>
                    <Modal.Body>
                        { loadError ? (
                            <Alert variant="danger">{ loadError }</Alert>
                        ) : !eventTicketTypes ? (
                            <Loading/>
                        ) : (
                            <React.Fragment>
                                { error && (
                                    <Alert variant="danger">{ error }</Alert>
                                )}
                                { eventTicketTypes.length > 0 && (
                                    <Form.Group controlId="ticketType">
                                        <Form.Label>Ticket type</Form.Label>
                                        <Form.Control
                                            as="select"
                                            onChange={ (event) => this.setState({ selectedEventTicketType: parseInt(event.target.value) })}
                                            value={ selectedEventTicketType }
                                            disabled={ sending }
                                        >
                                            <option key={0} value={0}/>
                                            { eventTicketTypes.map((eventTicketType) => (
                                                <option key={ eventTicketType.id } value={ eventTicketType.id }>
                                                    { eventTicketType.name }
                                                    { eventTicketType.paymentRequired && (
                                                        <React.Fragment>
                                                            {" - "}<PriceFormatter price={ eventTicketType.paymentPrice }/>
                                                        </React.Fragment>
                                                    )}
                                                </option>
                                            ))}
                                        </Form.Control>
                                    </Form.Group>
                                )}
                                <Form.Group controlId="email" className="mb-0">
                                    <Form.Label>Email</Form.Label>
                                    <Form.Control
                                        type="email"
                                        placeholder="example@drvr-app.com"
                                        onChange={ (event) => this.setState({ email: event.target.value })}
                                        value={ email }
                                        disabled={ sending }
                                    />
                                </Form.Group>
                            </React.Fragment>
                        )}
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={ this.props.handleClose } disabled={ sending }>
                            Sluiten
                        </Button>
                        <Button variant="primary" type="submit" disabled={ sending || !eventTicketTypes }>
                            { sending && (
                                <Spinner animation="border" variant="dark" size="sm" className="mr-2"/>
                            )}
                            Uitnodiging versturen
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal>
        );
    }

}

export default NewInviteModal;
