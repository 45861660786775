import React from "react";
import {
    OverlayTrigger,
    Tooltip
} from "react-bootstrap";

function FormHelpTooltip({ children, ...props }) {
    return (
        <span { ...props }>
            <OverlayTrigger overlay={
                <Tooltip id="tooltip-form-help">
                    { children }
                </Tooltip>
            }>
                <i className="fas fa-question-circle"/>
            </OverlayTrigger>
        </span>
    );
}

export default React.memo(FormHelpTooltip);
