import React, {
    useCallback
} from "react";
import {
    Dropdown
} from "react-bootstrap";
import PropTypes from "prop-types";

import Loading from "./Loading";
import NumberFormatter from "./formatters/NumberFormatter";

function FilterDropdown({ title, items, selectedIds, setSelectedIds, ...props }) {
    const onClear = useCallback(() => {
        setSelectedIds([]);
    }, [setSelectedIds]);
    const onClick = useCallback((item) => {
        if(selectedIds.includes(item.id)) {
            setSelectedIds(selectedIds.filter((id) => id !== item.id));
        } else {
            setSelectedIds([ ...selectedIds, item.id ]);
        }
    }, [selectedIds, setSelectedIds]);

    return (
        <Dropdown className="align-self-center">
            <Dropdown.Toggle
                id={ "filter" }
                { ...props }
            >
                <i className="fa-solid fa-filter mr-2"/>
                { title }
                { selectedIds.length > 0 && (
                    <span className="ml-2">
                        ({ selectedIds.length })
                    </span>
                )}
            </Dropdown.Toggle>

            <Dropdown.Menu>
                { !items ? (
                    <Loading/>
                ) : (
                    <React.Fragment>
                        { selectedIds.length > 0 && (
                            <Dropdown.Item onClick={ onClear } className="pl-3">
                                Selectie verwijderen
                            </Dropdown.Item>
                        )}
                        { items.map((item) => {
                            const onClickItem = (event) => {
                                event.preventDefault();
                                onClick(item);
                            };
                            const selected = selectedIds.includes(item.id);
                            return (
                                <Dropdown.Item key={ item.id } onClick={ onClickItem } className="px-3">
                                    <div className="custom-control custom-checkbox">
                                        <input
                                            type="checkbox"
                                            className="custom-control-input pointer-cursor"
                                            id={ item.id }
                                            checked={ selected }
                                            onChange={ onClick }
                                        />
                                        <label
                                            className="custom-control-label pointer-cursor pl-2 w-100"
                                            htmlFor={ item.id }
                                        >
                                            <div className="d-flex flex-row align-items-center w-100">
                                                <div className="flex-grow-1">
                                                    { item.name }
                                                </div>
                                                { item.amount !== undefined && (
                                                    <div className="text-muted ml-3">
                                                        (<NumberFormatter number={ item.amount }/>)
                                                    </div>
                                                )}
                                            </div>
                                        </label>
                                    </div>
                                </Dropdown.Item>
                            );
                        })}
                    </React.Fragment>
                )}
            </Dropdown.Menu>
        </Dropdown>
    );
}

FilterDropdown.propTypes = {
    title: PropTypes.string.isRequired,
    items: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired,
        amount: PropTypes.number
    })),
    selectedIds: PropTypes.arrayOf(PropTypes.number).isRequired,
    setSelectedIds: PropTypes.func.isRequired
};

export default React.memo(FilterDropdown);
