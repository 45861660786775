function rideDateInputToAPI(date) {
    if(!date) {
        return null;
    }
    if(date.trim().length === 0) {
        return null;
    }
    return date;
}

export default rideDateInputToAPI;
