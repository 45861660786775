import React from "react";
import axios from "axios";
import {
    Alert,
    Button,
    Spinner
} from "react-bootstrap";

import EventForm from "./components/EventForm";
import {
    withOrganisationsContext
} from "../../../../context/OrganisationsContext";
import getEventErrorMessage from "../getEventErrorMessage";

class EventDetailEdit extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            event: null,
            error: null,
            success: false,
            loading: false
        };
        this.onChangeEvent = this.onChangeEvent.bind(this);
        this.setEvent = this.setEvent.bind(this);
    }

    componentDidMount() {
        this.setState({ event: this.props.event });
    }

    componentDidUpdate(prevProps, _prevState, _snapshot) {
        if(prevProps.event !== this.props.event) {
            this.setState({ event: this.props.event });
        }
    }

    setEvent() {
        this.setState({ loading: true, error: null, success: false });
        const {
            name,
            description,
            infoWebpage,
            startDate,
            endDate,
            chatAudioAutoplayMaxDistance,
            publiclyVisible
        } = this.state.event;
        const currentOrganisation = this.props.organisationsContext.currentOrganisation;
        axios.post("/setEvent", {
            eventId: this.props.event.id,
            organisationId: currentOrganisation.id,
            name,
            description,
            infoWebpage,
            startDate,
            endDate,
            chatAudioAutoplayMaxDistance,
            publiclyVisible: publiclyVisible ? 1 : 0
        })
            .then((response) => {
                if(response.data.valid) {
                    this.props.setEvent(response.data.event);
                    this.setState({ success: true });
                } else {
                    this.setState({ error: getEventErrorMessage(response.data.error) });
                }
            })
            .catch((error) => {
                console.error(error);
                this.setState({ error: "Er ging iets fout. Probeer het later opnieuw." });
            })
            .finally(() => {
                window.scroll({ top: 0, behavior: "smooth" });
                this.setState({ loading: false });
            });
    }

    onChangeEvent(changes) {
        this.setState((prevState) => {
            return { event: { ...prevState.event, ...changes }, success: false };
        });
    }

    render() {
        const { event, error, loading, success } = this.state;
        return (
            <React.Fragment>
                { success && (
                    <Alert variant="success">
                        Wijzigingen zijn opgeslagen.
                    </Alert>
                )}
                { error && (
                    <Alert variant="danger">
                        { error }
                    </Alert>
                )}
                <EventForm
                    event={ event }
                    setEvent={ this.onChangeEvent }
                    loading={ loading }
                />
                <Button
                    variant="primary"
                    className="float-right"
                    disabled={ loading }
                    onClick={ this.setEvent }
                >
                    { loading && (
                        <Spinner animation="border" variant="dark" size="sm" className="mr-2"/>
                    )}
                    Opslaan
                </Button>
            </React.Fragment>
        );
    }
}

export default withOrganisationsContext(EventDetailEdit);
