import React from "react";

function RideEditSidebarMarkersEmptyState() {
    return (
        <div className="text-center pt-4 pb-2">
            <h4>Voeg eerste marker toe</h4>
            <p>
                Klik op de onderstaande knop om een marker toe te voegen. Markers zijn om op bepaalde punten een melding
                aan de deelnemer te tonen.
            </p>
            <div className="d-flex flex-row" style={{fontSize: "2.5rem"}}>
                <div className="flex-grow-1 mr-1 arrow-animation">
                    <i className="fa-solid fa-arrow-down fa-fw"/>
                </div>
                <div className="flex-grow-1 ml-1 arrow-animation">
                    <i className="fa-solid fa-arrow-down fa-fw"/>
                </div>
            </div>
        </div>
    );
}

export default React.memo(RideEditSidebarMarkersEmptyState);
