import React from "react";
import axios from "axios";
import {
    Link
} from "react-router-dom";
import {
    Alert,
    Button,
    OverlayTrigger,
    Table,
    Tooltip
} from "react-bootstrap";
import Skeleton from "react-loading-skeleton";

import Loading from "../../../../components/Loading";
import RemoveParticipantModal from "./modal/RemoveParticipantModal";
import SendPushNotificationModal from "./modal/SendPushNotificationModal";
import DateFormatter from "../../../../components/DateFormatter";
import PriceFormatter from "../../../../components/formatters/priceFormatter";
import FilterDropdown from "../../../../components/FilterDropdown";
import priceFormatter from "../../../../components/formatters/priceFormatter";
import ResendTicketConfirmationModal from "./modal/ResendTicketConfirmationModal";
import ResendAllTicketConfirmationsModal from "./modal/ResendAllTicketConfirmationsModal";

class EventDetailParticipants extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            allParticipants: null,
            participants: null,
            eventTicketTypes: null,
            error: null,

            ticketScannedUpdating: null,
            updateError: null,

            // Filters
            filterEventTicketTypeIds: [],
            filterSearch: "",

            showRemoveParticipantModal: null,
            showSendPushNotificationModal: null,

            showResendTicketConfirmationModal: false,
            resentTicketConfirmationParticipant: null,

            showResendAllTicketConfirmationsModal: false
        };
    }

    componentDidMount() {
        this.getParticipants();
        this.getEventTicketTypes();
    }

    componentDidUpdate(_prevProps, prevState, _snapshot) {
        if(prevState.filterEventTicketTypeIds !== this.state.filterEventTicketTypeIds) {
            this.setState({ participants: this.filterParticipants(this.state.allParticipants) });
        } else if(prevState.filterSearch !== this.state.filterSearch) {
            this.setState({ participants: this.filterParticipants(this.state.allParticipants) });
        }
    }

    getParticipants() {
        this.setState({ participants: null, error: null });
        axios.post("/getEventParticipants", { eventId: this.props.event.id })
            .then((response) => {
                if(response.data.valid) {
                    const allParticipants = response.data.participants;
                    const filteredParticipants = this.filterParticipants(allParticipants);
                    this.setState({ allParticipants, participants: filteredParticipants });
                } else {
                    this.setState({ error: "Er ging iets fout. Probeer het later opnieuw. (" + response.data.error + ")" });
                }
            })
            .catch(() => {
                this.setState({ error: "Er ging iets fout. Probeer het later opnieuw." });
            });
    }

    filterParticipants(participants) {
        const {
            filterEventTicketTypeIds,
            filterSearch
        } = this.state;
        return participants?.filter((participant) => {
            if(filterEventTicketTypeIds.length > 0 && !filterEventTicketTypeIds.includes(participant.eventTicketType?.id)) {
                return false;
            }
            if(filterSearch.trim().length > 0) {
                const searchQueries = filterSearch.split(" ");
                for(const query of searchQueries) {
                    if(participant.name.toLowerCase().includes(query.toLowerCase())) {
                        return true;
                    }
                    if(participant.email.toLowerCase().includes(query.toLowerCase())) {
                        return true;
                    }
                    if(participant.eventTicketType && participant.eventTicketType.name.toLowerCase().includes(query.toLowerCase())) {
                        return true;
                    }
                }
                return false;
            }
            return true;
        });
    }

    getEventTicketTypes() {
        this.setState({ eventTicketTypes: null, error: null });
        axios.post("/getEventTicketTypes", { eventId: this.props.event.id })
            .then((response) => {
                this.setState({ eventTicketTypes: response.data.eventTicketTypes });
            })
            .catch((error) => {
                console.error(error);
                this.setState({ error: "Er ging iets fout. Probeer het later opnieuw." });
            });
    }

    setEventTicketScanned(participantId, scanned) {
        this.setState({ ticketScannedUpdating: participantId, updateError: null });
        axios.post("/setEventParticipantTicketScanned", {
            eventId: this.props.event.id,
            participantId,
            scanned: scanned ? 1 : 0
        })
            .then((response) => {
                if(response.data.valid) {
                    const allParticipants = response.data.participants;
                    const filteredParticipants = this.filterParticipants(allParticipants);
                    this.setState({ allParticipants, participants: filteredParticipants });
                } else {
                    this.setState({ updateError: "Er ging iets fout. Probeer het later opnieuw. (" + response.data.error + ")" });
                }
            })
            .catch(() => {
                this.setState({ updateError: "Er ging iets fout. Probeer het later opnieuw." });
            })
            .finally(() => {
                this.setState({ ticketScannedUpdating: null });
            });
    }

    render() {
        const {
            baseUrl,
            event
        } = this.props;
        const {
            allParticipants,
            participants,
            eventTicketTypes,
            error,

            ticketScannedUpdating,
            updateError,

            filterEventTicketTypeIds,
            filterSearch,

            showRemoveParticipantModal,
            showSendPushNotificationModal,

            showResendTicketConfirmationModal,
            resentTicketConfirmationParticipant,

            showResendAllTicketConfirmationsModal
        } = this.state;
        return (
            <React.Fragment>
                <RemoveParticipantModal
                    show={ showRemoveParticipantModal != null }
                    event={ event }
                    participant={ showRemoveParticipantModal }
                    handleClose={ () => this.setState({ showRemoveParticipantModal: null }) }
                    handleSuccess={ () => this.getParticipants(this.props.event.id) }
                />
                <ResendTicketConfirmationModal
                    show={ showResendTicketConfirmationModal }
                    event={ event }
                    participant={ resentTicketConfirmationParticipant }
                    handleClose={ () => this.setState({ showResendTicketConfirmationModal: false }) }
                    handleSuccess={ () => this.getParticipants(this.props.event.id) }
                />
                <ResendAllTicketConfirmationsModal
                    show={ showResendAllTicketConfirmationsModal }
                    event={ event }
                    participants={ allParticipants }
                    handleClose={ () => this.setState({ showResendAllTicketConfirmationsModal: false }) }
                    handleSuccess={ () => this.getParticipants(event.id) }
                />

                <SendPushNotificationModal
                    show={ showSendPushNotificationModal }
                    eventId={ event.id }
                    handleClose={ () => { this.setState({ showSendPushNotificationModal: false }); } }
                    handleSuccess={ () => {  } }
                />

                <div className="d-flex flex-column flex-lg-row align-items-lg-center mb-3" style={{ gap: "1rem" }}>
                    <div className="d-flex flex-row align-items-center flex-grow-1" style={{ gap: "1rem" }}>
                        <div>
                            { eventTicketTypes?.length > 0 && (
                                <FilterDropdown
                                    title="Ticket Type"
                                    items={ eventTicketTypes?.map((ticketType) => ({
                                        name: ticketType.name + (ticketType.paymentRequired ? " - " + priceFormatter({ price: ticketType.paymentPrice }) : ""),
                                        id: ticketType.id,
                                        amount: ticketType.amountOfParticipants
                                    })) }
                                    selectedIds={ filterEventTicketTypeIds }
                                    setSelectedIds={ (filterEventTicketTypeIds) => this.setState({ filterEventTicketTypeIds }) }
                                    variant="secondary"
                                />
                            ) }
                        </div>
                        <div className="text-muted">
                            { participants?.length ?? 0 } deelnemer{ participants?.length !== 1 ? "s" : "" }
                        </div>
                    </div>
                    <div className="d-flex flex-row align-items-center" style={{ gap: "1rem" }}>
                        <div>
                            <input
                                type="text"
                                className="form-control"
                                placeholder="Zoeken"
                                value={ filterSearch }
                                onChange={ (event) => this.setState({ filterSearch: event.target.value }) }
                            />
                        </div>
                        <div className="d-flex" style={{ gap: "0.5rem" }}>
                            { !allParticipants ? (
                                <Skeleton height={ 38 } width={ 169 }/>
                            ) : allParticipants.length > 0 && (
                                <OverlayTrigger overlay={
                                    <Tooltip id="tooltip-push-notification-all">
                                        Push Notificatie versturen naar alle deelnemers
                                    </Tooltip>
                                }>
                                    <Button
                                        variant="primary"
                                        onClick={ () => {
                                            this.setState({ showSendPushNotificationModal: true });
                                        } }
                                    >
                                        <span className="d-none d-lg-inline">
                                            <i className="fas fa-bell-on mr-2"/>
                                            Push Notificatie
                                        </span>
                                        <span className="d-lg-none">
                                            <i className="fas fa-bell-on fa-fw"/>
                                        </span>
                                    </Button>
                                </OverlayTrigger>
                            ) }
                            { participants?.length > 0 && (
                                <OverlayTrigger overlay={
                                    <Tooltip id="tooltip-resend-all">
                                        Ticketbevestiging opnieuw versturen aan alle deelnemers
                                    </Tooltip>
                                }>
                                    <Button
                                        variant="secondary"
                                        onClick={ () => this.setState({ showResendAllTicketConfirmationsModal: true }) }
                                    >
                                        <i className="fa-solid fa-paper-plane-top fa-fw"/>
                                    </Button>
                                </OverlayTrigger>
                            ) }
                        </div>
                    </div>
                </div>

                { updateError && (
                    <Alert variant="danger">{ updateError }</Alert>
                )}

                <Table hover={ participants?.length > 0 } className="table-sm">
                    <thead className="thead-light">
                        <tr className="tr-sticky">
                            <th scope="col" className="d-none d-lg-table-cell text-left">Name</th>
                            <th scope="col" className="d-none d-lg-table-cell text-left">Email</th>
                            <th scope="col" className="d-lg-none text-left">Deelnemer</th>
                            <th scope="col" className="text-left">Ticket Type</th>
                            <th scope="col" className="text-left">Ticket gescand</th>
                            <th scope="col" className="text-right" style={ { minWidth: "110px" } }>Scan datum</th>
                            <th scope="col"/>
                        </tr>
                    </thead>
                    <tbody>
                        { error ? (
                            <tr>
                                <td colSpan={ 7 }>
                                    <Alert variant="danger">{ error }</Alert>
                                </td>
                            </tr>
                        ) : !participants || !allParticipants ? (
                            <tr>
                                <td colSpan={ 7 }>
                                    <Loading/>
                                </td>
                            </tr>
                        ) : allParticipants.length === 0 ? (
                            <tr>
                                <td colSpan={7} className="text-center">
                                    <h1><i className="fa-solid fa-users"/></h1>
                                    <h3>Geen deelnemers</h3>
                                    <p>Er zijn nog geen deelnemers voor dit evenement.</p>
                                    <Link to={ baseUrl + "/invites" } className="btn btn-primary">
                                        <i className="fa-solid fa-user-plus mr-2"/>Deelnemers uitnodigen
                                    </Link>
                                </td>
                            </tr>
                        ) : participants.length === 0 ? (
                            <tr>
                                <td colSpan={7} className="text-center">
                                    <h1><i className="fa-solid fa-users"/></h1>
                                    <h3>Geen deelnemers</h3>
                                    <p>Geen deelnemers gevonden voor je huidige filters.</p>
                                    <Button variant="primary" onClick={() => {
                                        this.setState({ filterEventTicketTypeIds: [], filterSearch: "" });
                                    }}>
                                        <i className="fa-solid fa-filter-slash mr-2"/>Filters verwijderen
                                    </Button>
                                </td>
                            </tr>
                        ) : participants.map((participant) =>
                            <tr key={ participant.id }>
                                <td className="d-none d-lg-table-cell"
                                    style={ { verticalAlign: "middle" } }>{ participant.name }</td>
                                <td className="d-none d-lg-table-cell"
                                    style={ { verticalAlign: "middle" } }>{ participant.email }</td>
                                <td className="d-lg-none" style={ { verticalAlign: "middle" } }>
                                    { participant.name }
                                    <br/>
                                    <small className="text-muted">{ participant.email }</small>
                                </td>
                                <td style={ { verticalAlign: "middle" } }>
                                    { participant.eventTicketType === null ? (
                                        <i className="text-muted">Geen</i>
                                    ) : (
                                        <React.Fragment>
                                            { participant.eventTicketType.name }{ " - " }
                                            { participant.eventTicketType.paymentRequired ? (
                                                <PriceFormatter price={ participant.eventTicketType.paymentPrice }/>
                                            ) : (
                                                "Gratis"
                                            ) }
                                        </React.Fragment>
                                    ) }
                                </td>
                                <td style={ { verticalAlign: "middle" } } className="text-left">
                                    <OverlayTrigger overlay={
                                        <Tooltip id={ "tooltip-ticket-scanned-" + participant.id }>
                                            { participant.ticketScanned ? (
                                                `Ticket gescand door ${ participant.ticketScannedByUser?.name ?? "onbekend" }`
                                            ) : (
                                                "Ticket niet gescand"
                                            ) }
                                        </Tooltip>
                                    }>
                                        <div className="d-flex flex-row align-items-center">
                                            { participant.ticketScanned ? (
                                                <i className="fas fa-check text-success fa-fw mr-2"/>
                                            ) : (
                                                <i className="fas fa-times text-danger fa-fw mr-2"/>
                                            ) }
                                            { !participant.ticketScanned ? (
                                                <i className="text-muted">Niet gescand</i>
                                            ) : participant.ticketScannedByUser === null ? (
                                                <i className="text-muted">Onbekend</i>
                                            ) : (
                                                participant.ticketScannedByUser.name
                                            ) }
                                        </div>
                                    </OverlayTrigger>
                                </td>
                                <td style={ { verticalAlign: "middle" } } className="text-right">
                                    { participant.ticketScanned ? (
                                        participant.ticketScanDate ? (
                                            <DateFormatter date={ participant.ticketScanDate }/>
                                        ) : (
                                            <i className="text-muted">Onbekend</i>
                                        )
                                    ) : (
                                        <i className="text-muted">Niet gescand</i>
                                    ) }
                                </td>
                                <td style={ { verticalAlign: "middle" } }>
                                    <div className="d-flex flex-row flex-nowrap w-100 justify-content-end">
                                        <OverlayTrigger overlay={
                                            <Tooltip id="tooltip-resend">
                                                Ticketbevestiging opnieuw versturen
                                            </Tooltip>
                                        }>
                                            <Button
                                                variant="secondary"
                                                size="sm"
                                                onClick={ () => this.setState({
                                                    showResendTicketConfirmationModal: true,
                                                    resentTicketConfirmationParticipant: participant
                                                }) }
                                            >
                                                <i className="fa-solid fa-paper-plane-top fa-fw"/>
                                            </Button>
                                        </OverlayTrigger>
                                        <OverlayTrigger overlay={
                                            <Tooltip id="tooltip-scanned">
                                                { participant.ticketScanned ? "Markeer als niet gescand" : "Markeer als gescand" }
                                            </Tooltip>
                                        }>
                                            <Button
                                                variant={ participant.ticketScanned ? "danger" : "success" }
                                                className="ml-1"
                                                size="sm"
                                                onClick={ () => this.setEventTicketScanned(participant.id, !participant.ticketScanned) }
                                                disabled={ ticketScannedUpdating }
                                            >
                                                <i className="fas fa-ticket fa-fw"/>
                                            </Button>
                                        </OverlayTrigger>
                                        <OverlayTrigger overlay={
                                            <Tooltip id="tooltip-delete">
                                                Loskoppelen
                                            </Tooltip>
                                        }>
                                            <Button
                                                variant="danger"
                                                className="ml-1"
                                                size="sm"
                                                onClick={ () => this.setState({ showRemoveParticipantModal: participant }) }
                                            >
                                                <i className="fas fa-trash fa-fw"/>
                                            </Button>
                                        </OverlayTrigger>
                                    </div>
                                </td>
                            </tr>
                        ) }
                    </tbody>
                </Table>
            </React.Fragment>
        );
    }
}

export default EventDetailParticipants;
