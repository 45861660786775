import React from "react";
import axios from "axios";
import moment from "moment";
import {
    Alert,
    Button,
    OverlayTrigger,
    Table,
    Tooltip
} from "react-bootstrap";

import Loading from "../../../../components/Loading";
import NewInviteModal from "./modal/NewInviteModal";
import RemoveInviteModal from "./modal/RemoveInviteModal";
import DateFormatter from "../../../../components/DateFormatter";
import ResendInviteModal from "./modal/ResendInviteModal";
import PriceFormatter from "../../../../components/formatters/priceFormatter";
import FilterDropdown from "../../../../components/FilterDropdown";
import priceFormatter from "../../../../components/formatters/priceFormatter";

class EventDetailInvites extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            allInvites: null,
            invites: null,
            eventTicketTypes: null,
            error: null,

            // Filters
            filterEventTicketTypeIds: [],
            filterSearch: "",

            showAddInviteModal: false,

            showRemoveInviteModal: false,
            removeInvite: null,

            showResendInviteModal: false,
            resendInvite: null
        };
        this.getInvites = this.getInvites.bind(this);
    }

    componentDidMount() {
        this.getInvites();
        this.getEventTicketTypes();
    }

    componentDidUpdate(_prevProps, prevState, _snapshot) {
        if(prevState.filterEventTicketTypeIds !== this.state.filterEventTicketTypeIds) {
            this.setState({ invites: this.filterInvites(this.state.allInvites) });
        } else if(prevState.filterSearch !== this.state.filterSearch) {
            this.setState({ invites: this.filterInvites(this.state.allInvites) });
        }
    }

    getInvites() {
        this.setState({ invites: null, error: null });
        axios.post("/getEventInvites", { eventId: this.props.event.id })
            .then((response) => {
                if(response.data.valid) {
                    const allInvites = response.data.invites;
                    const filteredInvites = this.filterInvites(allInvites);
                    this.setState({ allInvites, invites: filteredInvites });
                } else {
                    this.setState({ error: "Er ging iets fout. Probeer het later opnieuw. (" + response.data.error + ")" });
                }
            })
            .catch(() => {
                this.setState({ error: "Er ging iets fout. Probeer het later opnieuw." });
            });
    }

    filterInvites(invites) {
        const {
            filterEventTicketTypeIds,
            filterSearch
        } = this.state;
        return invites?.filter((invite) => {
            if(filterEventTicketTypeIds.length > 0 && !filterEventTicketTypeIds.includes(invite.ticketType?.id)) {
                return false;
            }
            if(filterSearch.trim().length > 0) {
                const searchQueries = filterSearch.split(" ");
                for(const query of searchQueries) {
                    if(invite.email.toLowerCase().includes(query.toLowerCase())) {
                        return true;
                    }
                    if(invite.ticketType && invite.ticketType.name.toLowerCase().includes(query.toLowerCase())) {
                        return true;
                    }
                    if(invite.hasExpired && "verlopen".includes(query.toLowerCase())) {
                        return true;
                    }
                }
                return false;
            }
            return true;
        });
    }

    getEventTicketTypes() {
        this.setState({ eventTicketTypes: null, error: null });
        axios.post("/getEventTicketTypes", { eventId: this.props.event.id })
            .then((response) => {
                this.setState({ eventTicketTypes: response.data.eventTicketTypes });
            })
            .catch((error) => {
                console.error(error);
                this.setState({ error: this.getErrorMessage(error) });
            });
    }

    render() {
        return (
            <React.Fragment>
                <NewInviteModal
                    show={ this.state.showAddInviteModal }
                    eventId={ this.props.event.id }
                    handleClose={ () => this.setState({ showAddInviteModal: false }) }
                    handleSuccess={ this.getInvites }
                />
                <RemoveInviteModal
                    show={ this.state.showRemoveInviteModal }
                    invite={ this.state.removeInvite }
                    handleClose={ () => this.setState({ showRemoveInviteModal: false }) }
                    handleSuccess={ this.getInvites }
                />
                <ResendInviteModal
                    show={ this.state.showResendInviteModal }
                    invite={ this.state.resendInvite }
                    handleClose={ () => this.setState({ showResendInviteModal: false }) }
                    handleSuccess={ this.getInvites }
                />

                <div className="d-flex flex-row align-items-center mb-3">
                    <div>
                        { this.state.eventTicketTypes?.length > 0 && (
                            <FilterDropdown
                                title="Ticket Type"
                                items={ this.state.eventTicketTypes?.map((ticketType) => ({
                                    name: ticketType.name + (ticketType.paymentRequired ? " - " + priceFormatter({ price: ticketType.paymentPrice }) : ""),
                                    id: ticketType.id,
                                    amount: ticketType.amountOfInvites
                                })) }
                                selectedIds={ this.state.filterEventTicketTypeIds }
                                setSelectedIds={ (filterEventTicketTypeIds) => this.setState({ filterEventTicketTypeIds }) }
                                variant="secondary"
                            />
                        )}
                    </div>
                    <div className="flex-grow-1 ml-3 text-muted">
                        { this.state.invites?.length ?? 0 } uitnodiging{ this.state.invites?.length !== 1 ? "en" : "" }
                    </div>
                    <div className="ml-3">
                        <input
                            type="text"
                            className="form-control"
                            placeholder="Zoeken"
                            value={ this.state.filterSearch }
                            onChange={ (event) => this.setState({ filterSearch: event.target.value }) }
                        />
                    </div>
                    <div className="ml-3">
                        { this.state.allInvites?.length > 0 && (
                            <Button variant="primary" onClick={ () => this.setState({ showAddInviteModal: true }) }>
                                <i className="fas fa-plus mr-2"/>
                                Nieuwe uitnodiging
                            </Button>
                        )}
                    </div>
                </div>

                <Table hover={ this.state.invites?.length > 0 } className="table-sm">
                    <thead className="thead-light">
                        <tr className="tr-sticky">
                            <th className="text-left" scope="col">Email</th>
                            <th className="text-left" scope="col">Aanmaak datum</th>
                            <th className="text-left" scope="col">Gebruikt</th>
                            <th className="text-left" scope="col">Gebruik datum</th>
                            { this.state.eventTicketTypes?.length > 0 && (
                                <th className="text-left" scope="col">Ticket Type</th>
                            )}
                            <th className="text-left" scope="col">Geldig</th>
                            <th scope="col"/>
                        </tr>
                    </thead>
                    <tbody>
                        { this.state.error ? (
                            <tr>
                                <td colSpan={ 7 }>
                                    <Alert variant="danger">{ this.state.error }</Alert>
                                </td>
                            </tr>
                        ) : !this.state.invites ? (
                            <tr>
                                <td colSpan={ 7 }>
                                    <Loading/>
                                </td>
                            </tr>
                        ) : this.state.allInvites.length === 0 ? (
                            <tr>
                                <td colSpan={ 7 } className="text-center">
                                    <h1><i className="fa-solid fa-envelope"/></h1>
                                    <h3>Geen uitnodigingen</h3>
                                    <p>Er zijn nog geen uitnodigingen voor dit evenement.</p>
                                    <Button
                                        variant="primary"
                                        onClick={ () => {
                                            this.setState({ showAddInviteModal: true });
                                        }}
                                    >
                                        <i className="fa-solid fa-plus mr-2"/>Nieuwe uitnodiging
                                    </Button>
                                </td>
                            </tr>
                        ) : this.state.invites.length === 0 ? (
                            <tr>
                                <td colSpan={ 7 } className="text-center">
                                    <h1><i className="fa-solid fa-envelope"/></h1>
                                    <h3>Geen uitnodigingen</h3>
                                    <p>Geen uitnodigingen gevonden voor je huidige filters.</p>
                                    <Button
                                        variant="primary"
                                        onClick={ () => {
                                            this.setState({ filterEventTicketTypeIds: [], filterSearch: "" });
                                        }}
                                    >
                                        <i className="fa-solid fa-filter-slash mr-2"/>Filters verwijderen
                                    </Button>
                                </td>
                            </tr>
                        ) : this.state.invites.map((invite) =>
                            <tr key={ invite.id }>
                                <td style={{ verticalAlign: "middle" }}>
                                    { invite.email }
                                </td>
                                <td style={{ verticalAlign: "middle" }}>
                                    <DateFormatter date={ invite.date }/>
                                </td>
                                <td style={{ verticalAlign: "middle" }}>
                                    { invite.used ? (
                                        <React.Fragment>
                                            <i className="fas fa-check fa-fw text-success mr-2"/>
                                            { invite.usedBy === null ? (
                                                <i className="text-muted">Onbekend</i>
                                            ) : (
                                                <React.Fragment>
                                                    { invite.usedBy.name }
                                                </React.Fragment>
                                            )}
                                        </React.Fragment>
                                    ) : (
                                        <React.Fragment>
                                            <i className="fas fa-times fa-fw text-danger mr-2"/>
                                            <i className="text-muted">Niet gebruikt</i>
                                        </React.Fragment>
                                    )}
                                </td>
                                <td style={{ verticalAlign: "middle" }}>
                                    { invite.usedDate === null ? (
                                        <i className="text-muted">Niet gebruikt</i>
                                    ) : (
                                        <DateFormatter date={ invite.usedDate }/>
                                    )}
                                </td>
                                { this.state.eventTicketTypes?.length > 0 && (
                                    <td style={{ verticalAlign: "middle" }}>
                                        { invite.ticketType === null ? (
                                            <i className="text-muted">Geen</i>
                                        ) : (
                                            <React.Fragment>
                                                { invite.ticketType.name }{" - "}
                                                { invite.ticketType.paymentRequired ? (
                                                    <PriceFormatter price={ invite.ticketType.paymentPrice }/>
                                                ) : (
                                                    "Gratis"
                                                )}
                                            </React.Fragment>
                                        )}
                                    </td>
                                )}
                                <td style={{verticalAlign: "middle"}}>
                                    { invite.used ? (
                                        <React.Fragment>
                                            <i className="fas fa-check fa-fw text-muted mr-2"/>
                                            <span className="text-muted">Gebruikt</span>
                                        </React.Fragment>
                                    ) : invite.hasExpired ? (
                                        <React.Fragment>
                                            <i className="fas fa-times fa-fw text-danger mr-2"/>
                                            <span className="text-danger">Verlopen</span>
                                        </React.Fragment>
                                    ) : (moment().diff(moment(invite.expirationDate), "days") / moment(invite.date).diff(moment(invite.expirationDate), "days") < 0.5) ? (
                                        <React.Fragment>
                                            <i className="fas fa-triangle-exclamation fa-fw text-warning mr-2"/>
                                            <span className="text-warning">Geldig tot <DateFormatter date={ invite.expirationDate }/></span>
                                        </React.Fragment>
                                    ) : (
                                        <React.Fragment>
                                            <i className="fas fa-check fa-fw text-success mr-2"/>
                                            <span className="text-success">Geldig tot <DateFormatter date={ invite.expirationDate }/></span>
                                        </React.Fragment>
                                    )}
                                </td>
                                <td style={{verticalAlign: "middle", textAlign: "right"}}>
                                    {!invite.used && (
                                        <OverlayTrigger overlay={
                                            <Tooltip id="tooltip-resend">
                                                Uitnodiging opnieuw versturen
                                            </Tooltip>
                                        }>
                                            <Button
                                                variant="secondary"
                                                className="ml-2"
                                                size="sm"
                                                onClick={() => this.setState({
                                                    showResendInviteModal: true,
                                                    resendInvite: invite
                                                })}
                                            >
                                                <i className="fa-solid fa-paper-plane-top fa-fw"/>
                                            </Button>
                                        </OverlayTrigger>
                                    )}
                                    <OverlayTrigger overlay={
                                        <Tooltip id="tooltip-invite-delete">
                                            Uitnodiging verwijderen
                                        </Tooltip>
                                    }>
                                        <Button
                                            variant="danger"
                                            className="ml-2"
                                            size="sm"
                                            onClick={() => this.setState({
                                                showRemoveInviteModal: true,
                                                removeInvite: invite
                                            })}
                                        >
                                            <i className="fas fa-trash fa-fw"/>
                                        </Button>
                                    </OverlayTrigger>
                                </td>
                            </tr>
                        )}
                    </tbody>
                </Table>
            </React.Fragment>
        );
    }
}

export default EventDetailInvites;
