import React, {
    useMemo,
    useState
} from "react";
import moment from "moment";
import Skeleton from "react-loading-skeleton";

import NumberFormatter from "../../../../../components/formatters/NumberFormatter";

function TableRow({ title, children }) {
    return (
        <tr>
            <th className="text-right p-1">
                { title }
            </th>
            <td className="p-1">
                { children }
            </td>
        </tr>
    );
}

function RideSidebarGeoJSONSummary({ geoJSON }) {
    const [error, setError] = useState(null);

    const route = useMemo(() => {
        if(!geoJSON) {
            return null;
        }
        if(!geoJSON.routes || geoJSON.routes.length === 0) {
            if(geoJSON.message) {
                setError(geoJSON.message);
            }
            return null;
        }
        return geoJSON.routes[0];
    }, [geoJSON]);

    const momentDuration = useMemo(() => {
        if(!route) {
            return null;
        }
        const duration = moment.duration(Math.ceil(route.duration / 60) * 60 * 1000);
        const years = duration.years();
        const months = duration.months();
        const days = duration.days();
        const hours = duration.hours();
        const minutes = duration.minutes();

        let stringBuilder = [];
        if(years > 0) {
            stringBuilder.push(`${years} jaar`);
        }
        if(months > 0) {
            if(months === 1) {
                stringBuilder.push(`${months} maand`);
            } else {
                stringBuilder.push(`${months} maanden`);
            }
        }
        if(days > 0) {
            if(days === 1) {
                stringBuilder.push(`${days} dag`);
            } else {
                stringBuilder.push(`${days} dagen`);
            }
        }
        if(hours > 0) {
            stringBuilder.push(`${hours} uur`);
        }
        if(minutes > 0) {
            if(minutes === 1) {
                stringBuilder.push(`${minutes} minuut`);
            } else {
                stringBuilder.push(`${minutes} minuten`);
            }
        }

        if(stringBuilder.length === 0) {
            return "Onbekend";
        }
        if(stringBuilder.length === 1) {
            return stringBuilder[0];
        }
        const last = stringBuilder.pop();
        let string = stringBuilder.join(", ");
        string += " en " + last;
        return string;
    }, [route]);

    if(error) {
        return null;
    }

    return (
        <div className="card mb-3">
            <div className="card-body">
                <table className="table table-borderless my-0">
                    <tbody>
                        <TableRow title="Afstand">
                            { route ? (
                                <React.Fragment>
                                    <NumberFormatter
                                        number={ route.distance / 1000 }
                                        maximumFractionDigits={ 1 }
                                    /> km
                                </React.Fragment>
                            ) : (
                                <Skeleton/>
                            )}
                        </TableRow>
                        <TableRow title="Duur">
                            { momentDuration ? momentDuration : (
                                <Skeleton/>
                            )}
                        </TableRow>
                    </tbody>
                </table>
            </div>
        </div>
    );
}

export default React.memo(RideSidebarGeoJSONSummary);
