import {
    useMemo,
    useState
} from "react";
import isFunction from "is-function";

function useStateHistory(initialState) {
    const [state, setState] = useState(initialState);
    const [stateHistory, setStateHistory] = useState([]);
    const [stateFuture, setStateFuture] = useState([]);

    const addToHistory = useMemo(() => {
        return (stateToAdd) => {
            setStateHistory((prevHistory) => {
                return [
                    ...prevHistory,
                    stateToAdd
                ];
            });
        };
    }, []);
    const addToFuture = useMemo(() => {
        return (stateToAdd) => {
            setStateFuture((prevFuture) => {
                return [
                    ...prevFuture,
                    stateToAdd
                ];
            });
        };
    }, []);

    const undo = useMemo(() => {
        return () => {
            setState((prevState) => {
                addToFuture(prevState);
                const newState = stateHistory[stateHistory.length - 1];
                setStateHistory((prevHistory) => {
                    return prevHistory.slice(0, -1);
                });
                return newState;
            });
        };
    }, [addToFuture, stateHistory]);
    const redo = useMemo(() => {
        return () => {
            setState((prevState) => {
                addToHistory(prevState);
                const newState = stateFuture[stateFuture.length - 1];
                setStateFuture((prevFuture) => {
                    return prevFuture.slice(0, -1);
                });
                return newState;
            });
        };
    }, [addToHistory, stateFuture]);
    const resetHistory = useMemo(() => {
        return () => {
            setStateHistory([]);
            setStateFuture([]);
        };
    }, []);

    const setNewState = useMemo(() => {
        return (newState, noHistory = false) => {
            setState((prevState) => {
                const newValue = isFunction(newState) ? newState(prevState) : newState;
                if(!noHistory) {
                    addToHistory(prevState);
                    setStateFuture([]);
                }
                return newValue;
            });
        };
    }, [addToHistory]);

    return {
        state,
        setState: setNewState,
        undo,
        redo,
        resetHistory,
        canUndo: stateHistory.length > 0,
        canRedo: stateFuture.length > 0
    };
}

export default useStateHistory;
