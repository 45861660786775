import React, {
    useMemo,
    useState
} from "react";
import {
    Button,
    FormControl,
    InputGroup
} from "react-bootstrap";
import {
    useSortable
} from "@dnd-kit/sortable";
import {
    CSS
} from "@dnd-kit/utilities";

function RideWaypointCard({ index, waypoint, onMouseEnter, onMouseLeave, onDelete, onWaypointRename }) {
    const [editing, setEditing] = useState(false);
    const [editName, setEditName] = useState("");
    const {
        attributes,
        listeners,
        setNodeRef,
        transform,
        transition,
        isDragging
    } = useSortable({ id: waypoint.id, disabled: editing });

    const name = useMemo(() => {
        if(waypoint.name && waypoint.name.trim().length > 0) {
            return waypoint.name.trim();
        }
        return "Waypoint";
    }, [waypoint]);

    const onClickEdit = useMemo(() => {
        return () => {
            setEditing(true);
            setEditName(name);
        };
    }, [name]);
    const onClickDelete = useMemo(() => {
        return () => {
            onDelete(waypoint);
        };
    }, [onDelete, waypoint]);

    const onChange = useMemo(() => {
        return (event) => {
            setEditName(event.target.value);
        };
    }, []);
    const onSave = useMemo(() => {
        return () => {
            setEditing(false);
            onWaypointRename(waypoint.id, editName);
            console.log(`Save name: ${editName}`);
        };
    }, [editName, waypoint]);
    const onCancel = useMemo(() => {
        return () => {
            setEditing(false);
        };
    }, []);

    return (
        <div
            ref={ setNodeRef }
            style={{
                transform: CSS.Transform.toString(transform),
                transition
            }}
            className="card card-hover"
            onMouseEnter={ () => onMouseEnter(waypoint) }
            onMouseLeave={ () => onMouseLeave(waypoint) }
        >
            <div className="card-body px-2">
                { editing ? (
                    <form onSubmit={ onSave }>
                        <InputGroup controlId="amount" className="mb-0">
                            <FormControl
                                type="text"
                                value={ editName }
                                onChange={ onChange }
                                placeholder="Naam"
                            />
                            <InputGroup.Append>
                                <Button variant="success" onClick={ onSave }>
                                    <i className="fas fa-save fa-fw"/>
                                </Button>
                                <Button variant="secondary" onClick={ onCancel }>
                                    <i className="fas fa-times fa-fw"/>
                                </Button>
                            </InputGroup.Append>
                        </InputGroup>
                    </form>
                ) : (
                    <div className="d-flex flex-row align-items-center">
                        <div className="d-flex flex-row flex-grow-1 align-items-center">
                            <Button
                                variant="link"
                                size="sm"
                                className="text-secondary"
                                { ...attributes }
                                { ...listeners }
                                style={{
                                    cursor: isDragging ? "grabbing" : "grab"
                                }}
                            >
                                <i className="fas fa-grip-lines"/>
                            </Button>
                            <div className="text-muted text-right mr-2" style={{width: "25px"}}>
                                {index + 1}
                            </div>
                            <div className="flex-grow-1 d-flex flex-column">
                                <div>
                                    {name}
                                </div>
                                {waypoint.new ? (
                                    <div>
                                        <span className="badge badge-success badge-pill">
                                            Nieuw
                                        </span>
                                    </div>
                                ) : waypoint.changed && (
                                    <div>
                                        <span className="badge badge-secondary badge-pill">
                                            Bewerkt
                                        </span>
                                    </div>
                                )}
                            </div>
                        </div>
                        <div>
                            <Button variant="link" size="sm" className="text-secondary" onClick={ onClickEdit }>
                                <i className="fas fa-pen"/>
                            </Button>
                            <Button variant="link" size="sm" className="text-danger" onClick={ onClickDelete }>
                                <i className="fas fa-trash-alt"/>
                            </Button>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
}

export default React.memo(RideWaypointCard);
