import React, {
    useCallback,
    useState
} from "react";
import axios from "axios";
import {
    Alert,
    Button,
    Modal,
    Spinner
} from "react-bootstrap";
import PropTypes from "prop-types";

import Loading from "../../../../../components/Loading";

function ResendTicketConfirmationModal({ show, handleClose, event, participant, handleSuccess }) {
    const [sending, setSending] = useState(false);
    const [error, setError] = useState(null);

    const resendTicketConfirmation = useCallback(async () => {
        setSending(true);
        setError(null);
        try {
            await axios.post("/resendTicketConfirmation", {
                eventId: event.id,
                participantId: participant.id
            });
            handleSuccess();
            handleClose();
        } catch(requestError) {
            console.error(requestError);
            setError("Er ging iets fout. Probeer het later opnieuw.");
            setSending(false);
        }
    }, [event, participant, handleSuccess, handleClose]);

    const onShow = useCallback(() => {
        setError(null);
        setSending(false);
    }, []);

    return (
        <Modal show={ show } onHide={ handleClose } onShow={ onShow }>
            <Modal.Header closeButton>
                <Modal.Title>Ticketbevestiging opnieuw versturen</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                { error && (
                    <Alert variant="danger">{ error }</Alert>
                )}
                { !participant || !event ? (
                    <Loading/>
                ) : (
                    <p className="mb-0">
                        Weet je zeker dat je de ticketbevestiging opnieuw wilt versturen aan <b>{ participant.email }</b>?
                    </p>
                )}
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={ handleClose } disabled={ sending }>
                    Sluiten
                </Button>
                <Button variant="primary" onClick={ resendTicketConfirmation } disabled={ sending }>
                    { sending && (
                        <Spinner animation="border" variant="dark" size="sm" className="mr-2"/>
                    )}
                    Opnieuw versturen
                </Button>
            </Modal.Footer>
        </Modal>
    );
}
ResendTicketConfirmationModal.propTypes = {
    show: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,
    event: PropTypes.object,
    participant: PropTypes.object,
    handleSuccess: PropTypes.func.isRequired
};

export default React.memo(ResendTicketConfirmationModal);
