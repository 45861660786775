import React, {
    useContext,
    useMemo,
    useState
} from "react";
import {
    Link
} from "react-router-dom";
import {
    Button,
    ButtonGroup
} from "react-bootstrap";
import {
    AuthenticationPage,
    AuthenticationPageColumn,
    AuthenticationPageFooter
} from "@zandor300/backoffice-framework";

import AuthenticatedUserContext from "../../context/AuthenticatedUserContext";
import Helmet from "../../components/Helmet";
import Copyright from "./components/Copyright";

import logo from "../../img/logo.svg";

function Login() {
    const authenticatedUserContext = useContext(AuthenticatedUserContext);

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);

    const onError = useMemo(() => {
        return (errorMessage) => {
            setError(errorMessage);
            setLoading(false);
        };
    }, []);
    const onSubmit = useMemo(() => {
        return (event) => {
            event.preventDefault();
            setError(null);
            setLoading(true);
            authenticatedUserContext.loginFunction({ email, password }, onError);
        };
    }, [authenticatedUserContext, email, password, onError]);

    return (
        <AuthenticationPage>
            <Helmet/>
            <AuthenticationPageColumn>
                <img src={ logo } alt="DRVR app" className="login-logo"/>
                <h3 className="mt-0 text-center text-muted mb-4">Login</h3>
                { error !== null && (
                    <div className="alert alert-danger">{ error }</div>
                )}
                <form onSubmit={ onSubmit }>
                    <div className="form-group">
                        <input
                            type="email"
                            className="form-control"
                            id="email"
                            placeholder="Email"
                            disabled={ loading }
                            value={ email }
                            onChange={(event) => setEmail(event.target.value) }
                        />
                    </div>
                    <div className="form-group">
                        <input
                            type="password"
                            className="form-control"
                            id="password"
                            placeholder="Wachtwoord"
                            disabled={ loading }
                            value={ password }
                            onChange={(event) => setPassword(event.target.value) }
                        />
                    </div>
                    <ButtonGroup className="align-items-center justify-content-end w-100">
                        <div className="clearfix">
                            <Button type="submit" variant="primary">
                                Inloggen
                            </Button>
                        </div>
                    </ButtonGroup>
                </form>

                <div className="text-center mt-5 mb-3">
                    <Link to="/forgot-password" className="text-secondary btn-sm">
                        Wachtwoord vergeten
                    </Link>
                </div>
            </AuthenticationPageColumn>
            <AuthenticationPageFooter>
                <Copyright/>
            </AuthenticationPageFooter>
        </AuthenticationPage>
    );
}

export default React.memo(Login);
