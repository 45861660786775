import React from "react";
import axios from "axios";
import {
    Alert,
    Button,
    Form,
    Modal,
    Spinner
} from "react-bootstrap";

class SendPushNotificationModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            message: "",
            updating: false,
            error: null
        };
    }

    sendEventPushNotification() {
        this.setState({ updating: true, error: null });
        axios.post("/sendEventPushNotification", { eventId: this.props.eventId, message: this.state.message })
            .then((response) => {
                this.setState({ updating: false });
                if(response.data.valid) {
                    this.props.handleSuccess();
                    this.closeModal();
                } else {
                    this.setState({ updating: false, error: "Er ging iets fout. Probeer het later opnieuw. (" + response.data.error + ")" });
                }
            })
            .catch(() => {
                this.setState({ updating: false, error: "Er ging iets fout. Probeer het later opnieuw." });
            });
    }

    closeModal() {
        this.setState({ message: "" });
        this.props.handleClose();
    }

    render() {
        const {
            updating,
            error,
            message
        } = this.state;
        return (
            <Modal show={this.props.show} onHide={ () => this.closeModal() }>
                <Modal.Header closeButton>
                    <Modal.Title>Push Notificatie versturen</Modal.Title>
                </Modal.Header>
                <Form onSubmit={ (event) => {
                    event.preventDefault();
                    this.setState({ error: null });
                    this.sendEventPushNotification();
                } }>
                    <Modal.Body>
                        { error ? (
                            <Alert variant="danger">{ error }</Alert>
                        ) : (
                            <Alert variant="warning">
                                Voorkom dat deze functie te vaak gebruikt wordt, gebruikers kunnen dit als spam aanzien.
                            </Alert>
                        )}
                        <Form.Group controlId="email" className="mb-0">
                            <Form.Label>Bericht</Form.Label>
                            <Form.Control
                                type="text"
                                onChange={ (e) => this.setState({ message: e.target.value })}
                                value={ message }
                            />
                        </Form.Group>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={ () => this.closeModal() } disabled={ updating }>
                            Sluiten
                        </Button>
                        <Button variant="primary" type="submit" disabled={ updating }>
                            { updating && (
                                <Spinner animation="border" variant="dark" size="sm" className="mr-2"/>
                            )}
                            Versturen
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal>
        );
    }

}

export default SendPushNotificationModal;
