import React, {
    useMemo
} from "react";

import defaultBanner from "../../img/banner.jpg";

function DesignSettingsExample({
    title,
    description,
    banner,
    logo
}) {
    const url = useMemo(() => {
        if(!banner) {
            return defaultBanner;
        }
        return banner.url;
    }, [banner]);
    return (
        <div
            className="event-design-demo-container"
            style={{
                height: "450px",
                backgroundImage: "linear-gradient(0deg, rgba(0,0,0,0.6) 0%, rgba(0,0,0,0.6) 35%, rgba(0,0,0,0) 45%, rgba(0,0,0,0) 100%), url(" + url + ")",
            }}
        >
            { logo ? (
                <img
                    src={ logo.url }
                    alt="Logo"
                    className="mb-2"
                    style={{
                        maxHeight: "100px",
                        maxWidth: "100%",
                    }}
                />
            ) : (
                <p
                    className="mb-2 text-white text-center"
                    style={{
                        fontSize: "1.3rem",
                        fontWeight: "bold",
                    }}
                >
                    { title }
                </p>
            )}
            <div className="description-container">
                <p>
                    { description }
                </p>
            </div>
            <span
                className="btn btn-primary btn-sm mt-2"
                style={{
                    cursor: "not-allowed",
                    width: "100%",
                }}
            >
                Meer informatie
            </span>
        </div>
    );
}

export default React.memo(DesignSettingsExample);
