import React from "react";
import {
    withRouter
} from "react-router-dom";
import {
    Sidebar,
    SidebarLink
} from "@zandor300/backoffice-framework";

import logo from "../../img/logo.svg";

function BackofficeSidebar({ match }) {
    return (
        <Sidebar
            logo={
                <img src={ logo } alt="DRVR"/>
            }
        >
            <SidebarLink
                to={ match.url + "/dashboard" }
                icon="fas fa-tachometer-alt"
            >
                Dashboard
            </SidebarLink>
            <SidebarLink
                to={ match.url + "/events" }
                icon="fas fa-calendar-alt"
            >
                Evenementen
            </SidebarLink>
            <SidebarLink
                to={ match.url + "/settings" }
                icon="fas fa-cog"
            >
                Instellingen
            </SidebarLink>
        </Sidebar>
    );
}

export default withRouter(React.memo(BackofficeSidebar));
