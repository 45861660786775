import React, {
    createContext
} from "react";
import axios from "axios";
import PropTypes from "prop-types";
import {
    matchPath,
    withRouter
} from "react-router-dom";

const OrganisationsContext = createContext(null);

class OrganisationsManagerInternal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            context: {
                organisations: null,
                currentOrganisation: undefined,
                error: null,
                refreshOrganisations: this.getOrganisations.bind(this)
            }
        };
    }

    componentDidMount() {
        this.getOrganisations();
    }

    // eslint-disable-next-line no-unused-vars
    componentDidUpdate(prevProps, prevState, snapshot) {
        if(this.props.location.pathname !== prevProps.location.pathname || this.state.context.organisations !== prevState.context.organisations) {
            this.updateCurrentOrganisation();
        }
    }

    updateCurrentOrganisation() {
        console.log("updateCurrentOrganisation");
        if(!this.state.context.organisations) {
            return;
        }
        const match = matchPath(this.props.location.pathname, {
            path: "/organisation/:organisationCodeName"
        });
        if(match === null) {
            this.setContextState({ currentOrganisation: undefined });
            return;
        }
        const currentCodeName = match.params.organisationCodeName;
        const currentOrganisation = this.state.context.organisations.find((organisation) => organisation.codeName === currentCodeName);
        this.setContextState({ currentOrganisation: currentOrganisation === undefined ? null : currentOrganisation });
    }

    setContextState(state) {
        this.setState({ context: { ...this.state.context, ...state }});
    }

    getOrganisations(noClear) {
        if(!noClear) {
            this.setContextState({ organisations: null });
        }
        this.setContextState({ error: null });
        axios.get("/getOrganisationsUserIsMemberOf")
            .then((response) => {
                if(response.data.valid) {
                    const organisations = response.data.organisations.sort((organisation1, organisation2) => {
                        if(organisation1.name < organisation2.name) return -1;
                        if(organisation1.name > organisation2.name) return 1;
                        return 0;
                    });
                    this.setContextState({ organisations });
                } else {
                    this.setContextState({ error: "Er ging iets fout bij het ophalen van de data. (" + response.data.error + ")", errorCode: response.data.error });
                }
            })
            .catch(() => {
                this.setContextState({ error: "Er ging iets fout bij het ophalen van de data." });
            });
    }

    render() {
        return (
            <OrganisationsContext.Provider value={ this.state.context }>
                { this.props.children }
            </OrganisationsContext.Provider>
        );
    }
}
OrganisationsManagerInternal.propTypes = {
    children: PropTypes.element,
    location: PropTypes.object
};
export const OrganisationsManager = withRouter(OrganisationsManagerInternal);

export function withOrganisationsContext(Component) {
    return function contextComponent(props) {
        return (
            <OrganisationsContext.Consumer>
                {context => <Component {...props} organisationsContext={context} />}
            </OrganisationsContext.Consumer>
        );
    };
}

export const OrganisationsContextPropTypes = PropTypes.shape({
    organisations: PropTypes.array,
    currentOrganisation: PropTypes.object,
    error: PropTypes.string,
    refreshOrganisations: PropTypes.func.isRequired
});

export default OrganisationsContext;
