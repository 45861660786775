import React from "react";

function RideEditSidebarRouteEmptyState() {
    return (
        <div className="text-center pt-4 pb-2">
            <h4>Voeg eerste waypoint toe</h4>
            <p>
                Klik op een van onderstaande knoppen om te beginnen met het intekenen van de route.
            </p>
            <div className="d-flex flex-row" style={{fontSize: "2.5rem"}}>
                <div className="flex-grow-1 mr-1 arrow-animation">
                    <i className="fa-solid fa-arrow-down fa-fw"/>
                </div>
                <div className="flex-grow-1 ml-1 arrow-animation">
                    <i className="fa-solid fa-arrow-down fa-fw"/>
                </div>
            </div>
        </div>
    );
}

export default React.memo(RideEditSidebarRouteEmptyState);
