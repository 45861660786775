import React from "react";
import {
    Redirect,
    Route,
    Switch
} from "react-router-dom";

import Login from "./Login";
import ForgotPassword from "./ForgotPassword";

function AuthenticationRouter() {
    return (
        <Switch>
            <Route path="/login" component={Login}/>
            <Route path="/forgot-password" component={ForgotPassword}/>

            <Route path="/">
                <Redirect
                    to={{
                        pathname: "/login",
                        state: { from: window.location.href }
                    }}
                />
            </Route>
        </Switch>
    );
}

export default React.memo(AuthenticationRouter);
