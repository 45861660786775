import React, {
    useEffect,
    useMemo,
    useState
} from "react";
import {
    Button,
    Modal
} from "react-bootstrap";

import EditMarkerForm from "./components/EditMarkerForm";

function EditMarkerModal({ show, handleClose, marker, onSave }) {
    const [unsavedMarker, setUnsavedMarker] = useState(null);

    useEffect(() => {
        if(!show) {
            return;
        }
        setUnsavedMarker(marker);
    }, [marker, show]);
    const onSaveInternal = useMemo(() => {
        return () => {
            onSave(unsavedMarker);
        };
    }, [unsavedMarker, onSave]);

    return (
        <Modal show={ show } onHide={ handleClose } backdropClassName="waypoint-delete-backdrop">
            <Modal.Header closeButton>
                <Modal.Title>
                    Marker bewerken
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <EditMarkerForm
                    rideMarker={ unsavedMarker }
                    setRideMarker={ (changes) => setUnsavedMarker({ ...unsavedMarker, ...changes }) }
                />
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={ handleClose }>
                    Annuleren
                </Button>
                <Button variant="primary" type="submit" onClick={ onSaveInternal }>
                    Opslaan
                </Button>
            </Modal.Footer>
        </Modal>
    );
}

export default React.memo(EditMarkerModal);
