import React, {
    useCallback
} from "react";
import {
    closestCenter,
    DndContext
} from "@dnd-kit/core";
import {
    arrayMove,
    SortableContext,
    verticalListSortingStrategy
} from "@dnd-kit/sortable";
import {
    restrictToFirstScrollableAncestor
} from "@dnd-kit/modifiers";

import RideWaypointCard from "../../../components/RideWaypointCard";

function RideEditSidebarRouteWaypointsListSortable({
    waypoints,
    setWaypoints,
    onMouseEnter,
    onMouseLeave,
    onDelete,
    onWaypointRename
}) {
    const onDragEnd = useCallback(({ active, over }) => {
        if(!active || !over) {
            return;
        }
        if(active.id === over.id) {
            return;
        }
        setWaypoints((prevWaypoints) => {
            const oldIndex = prevWaypoints.findIndex((waypoint) => waypoint.id === active.id);
            const newIndex = prevWaypoints.findIndex((waypoint) => waypoint.id === over.id);

            const updatedWaypoints = prevWaypoints.map((waypoint, index) => {
                if(index !== oldIndex && index !== newIndex) {
                    return waypoint;
                }
                return {
                    ...waypoint,
                    changed: true
                };
            });
            return arrayMove(updatedWaypoints, oldIndex, newIndex);
        });
    }, [waypoints, setWaypoints]);

    if(!waypoints) {
        return null;
    }

    return (
        <DndContext
            collisionDetection={ closestCenter }
            modifiers={ [restrictToFirstScrollableAncestor] }
            onDragEnd={ onDragEnd }
        >
            <SortableContext
                items={ waypoints }
                strategy={ verticalListSortingStrategy }
            >
                { waypoints.map((waypoint, index) => (
                    <RideWaypointCard
                        key={ waypoint.id }
                        index={ index }
                        waypoint={ waypoint }
                        onMouseEnter={ onMouseEnter }
                        onMouseLeave={ onMouseLeave }
                        onDelete={ onDelete }
                        onWaypointRename={ onWaypointRename }
                    />
                ))}
            </SortableContext>
        </DndContext>
    );
}

export default React.memo(RideEditSidebarRouteWaypointsListSortable);
