import React, {
    useCallback,
    useContext,
    useEffect,
    useState
} from "react";
import * as Sentry from "@sentry/react";
import axios from "axios";
import {
    Alert,
    Button,
    Modal
} from "react-bootstrap";

import RideEditor from "../editor/RideEditor";
import Loading from "../../../../../components/Loading";
import OrganisationsContext from "../../../../../context/OrganisationsContext";
import MapboxStyleDropdown from "../editor/components/MapboxStyleDropdown";

function RideLiveLocationModal({ show, handleClose, ride }) {
    const organisationsContext = useContext(OrganisationsContext);
    const [participants, setParticipants] = useState(null);
    const [error, setError] = useState(null);
    const [autoFollow, setAutoFollow] = useState(true);
    const [rideGeoJSON, setRideGeoJSON] = useState(null);

    useEffect(() => {
        if(!ride || !organisationsContext.currentOrganisation) {
            return;
        }
        axios.post("/getRideGeoJSON", { rideId: ride.id, organisationId: organisationsContext.currentOrganisation.id })
            .then((response) => {
                setRideGeoJSON(response.data.geojson);
            })
            .catch((error) => {
                console.error(error);
                Sentry.captureException(error);
            });
    }, [ride, organisationsContext]);

    const refreshParticipants = useCallback(async () => {
        setError(null);
        try {
            const response = await axios.post("/getUserLocationsForRide", { rideId: ride.id });
            setParticipants(response.data.users);
        } catch(error) {
            console.error(error);
            Sentry.captureException(error);
            setError("Er is een fout opgetreden. Probeer het later opnieuw.");
        }
    }, [ride]);

    useEffect(() => {
        if(!show) {
            return;
        }
        setParticipants(null);
        refreshParticipants();
        const timer = setInterval(refreshParticipants, 3 * 1000);
        return () => clearInterval(timer);
    }, [show, refreshParticipants]);

    const toggleAutoFollow = useCallback(() => {
        setAutoFollow((prevAutoFollow) => !prevAutoFollow);
    }, []);

    return (
        <Modal
            className="ride-editor-modal"
            size="fullscreen"
            show={ show }
            onHide={ handleClose }
        >
            <Modal.Header closeButton>
                <div className="d-flex align-items-center w-100">
                    <Modal.Title className="flex-grow-1">
                        <div className="pretitle">
                            Live locatie deelnemers
                        </div>
                        <div className="title">
                            {ride?.name}
                        </div>
                    </Modal.Title>
                    <div className="d-flex flex-row pl-3">
                        <Button variant="light" onClick={ toggleAutoFollow } className="ml-2">
                            { autoFollow ? (
                                <i className="fa-solid fa-location-pin fa-fw mr-2"></i>
                            ) : (
                                <i className="fa-solid fa-location-pin-slash fa-fw mr-2"></i>
                            )}
                            Volgen { autoFollow ? "aan" : "uit" }
                        </Button>
                        <MapboxStyleDropdown className="ml-2"/>
                    </div>
                </div>
            </Modal.Header>
            <Modal.Body className="p-0 flex-grow-1 d-flex">
                <div className="d-flex flex-row w-100 flex-grow-1">
                    <div className="mapbox-error-overlay">
                        <div className="error-container">
                            { !participants && (
                                <Loading/>
                            )}
                            {error && (
                                <Alert variant="danger">{error}</Alert>
                            )}
                        </div>
                    </div>
                    {participants && (
                        <div className="mapbox-modal-container">
                            <RideEditor
                                geoJSON={ rideGeoJSON }
                                mode="live"
                                markersDraggable={false}
                                participants={participants}
                                autoFollow={ autoFollow }
                                onInteraction={ () => setAutoFollow(false) }
                            />
                        </div>
                    )}
                </div>
            </Modal.Body>
        </Modal>
    );
}

export default React.memo(RideLiveLocationModal);
