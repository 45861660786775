import React, {
    useMemo
} from "react";
import GitInfo from "react-git-info/macro";

function GridSenseVersion() {
    const commitHashShort = useMemo(() => {
        return GitInfo().commit.shortHash;
    }, []);

    return (
        <React.Fragment>
            DRVR Backoffice
            {" "}
            ({ commitHashShort })
        </React.Fragment>
    );
}

export default React.memo(GridSenseVersion);
