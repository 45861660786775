import {
    useContext,
    useEffect,
    useMemo,
    useState
} from "react";
import axios from "axios";
import isFunction from "is-function";
import * as Sentry from "@sentry/react";

import OrganisationsContext from "../../../../../../context/OrganisationsContext";
import useStateHistory from "./useStateHistory";

function useRideEditorStateManager({ ride, setRide, rideGeoJSON }) {
    const organisationsContext = useContext(OrganisationsContext);

    const {
        state,
        setState,
        undo,
        redo,
        resetHistory,
        canUndo,
        canRedo
    } = useStateHistory({
        waypoints: null,
        markers: null,
        geoJSON: null,
        geoJSONUpdated: false
    });

    const [hasChanges, setHasChanges] = useState(false);
    const [geoJSONError, setGeoJSONError] = useState(null);
    const [loading, setLoading] = useState(false);
    const [saving, setSaving] = useState(false);
    const [success, setSuccess] = useState(null);

    const setWaypoints = useMemo(() => {
        return (waypoints, skipChanges = false) => {
            setState((prevState) => {
                const newState = isFunction(waypoints) ? waypoints(prevState.waypoints) : waypoints;
                return {
                    ...prevState,
                    waypoints: newState,
                    geoJSONUpdated: false
                };
            }, skipChanges);
            if(!skipChanges) {
                setHasChanges(true);
            }
        };
    }, []);
    const setMarkers = useMemo(() => {
        return (markers, skipChanges = false) => {
            setState((prevState) => {
                const newState = isFunction(markers) ? markers(prevState.markers) : markers;
                return {
                    ...prevState,
                    markers: newState
                };
            }, skipChanges);
            if(!skipChanges) {
                setHasChanges(true);
            }
        };
    }, []);
    const setGeoJSON = useMemo(() => {
        return (geoJSON) => {
            setState((prevState) => {
                const newState = isFunction(geoJSON) ? geoJSON(prevState.waypoints) : geoJSON;
                return {
                    ...prevState,
                    geoJSON: newState,
                    geoJSONUpdated: true
                };
            }, true);
        };
    }, []);

    useEffect(() => {
        if(!ride) {
            return;
        }
        setWaypoints(ride.waypoints.map((waypoint) => {
            waypoint.changed = false;
            return waypoint;
        }), true);
        setMarkers(ride.markers.map((marker) => {
            marker.changed = false;
            return marker;
        }), true);
        resetHistory();
        setHasChanges(false);
    }, [ride, setWaypoints, setMarkers]);
    useEffect(() => {
        if(!rideGeoJSON) {
            return;
        }
        setGeoJSON(rideGeoJSON);
        setGeoJSONError(null);
    }, [rideGeoJSON, setGeoJSON]);

    useEffect(() => {
        const {
            waypoints,
            geoJSONUpdated
        } = state;
        if(geoJSONUpdated || !ride || !organisationsContext.currentOrganisation || !waypoints) {
            return;
        }
        const foundChanged = waypoints.find((waypoint) => waypoint.changed);
        if(foundChanged === undefined) {
            return;
        }
        setLoading(true);
        setGeoJSONError(null);
        axios.post("/getRideGeoJSONUnsaved", {
            rideId: ride.id,
            organisationId: organisationsContext.currentOrganisation.id,
            waypoints: JSON.stringify(waypoints)
        })
            .then((response) => {
                setLoading(false);
                if(response.data.valid) {
                    const newGeoJSON = response.data.geojson;
                    if(!newGeoJSON.routes || newGeoJSON.routes.length === 0) {
                        if(newGeoJSON.message) {
                            setGeoJSONError(newGeoJSON.message);
                        }
                        setGeoJSON(null);
                        return;
                    }
                    setGeoJSON(newGeoJSON);
                } else {
                    setGeoJSONError("Er ging iets fout. Probeer het later opnieuw. (" + response.data.error + ")");
                }
            })
            .catch(() => {
                setGeoJSONError("Er ging iets fout. Probeer het later opnieuw.");
            })
            .finally(() => {
                setLoading(false);
            });
    }, [ride, organisationsContext, state.waypoints]);

    const save = useMemo(() => {
        return async () => {
            setSaving(true);
            setGeoJSONError(null);
            setSuccess(null);
            try {
                const response = await axios.post("/setRideWaypointsAndMarkers", {
                    rideId: ride.id,
                    organisationId: organisationsContext.currentOrganisation.id,
                    waypoints: JSON.stringify(state.waypoints),
                    markers: JSON.stringify(state.markers)
                });
                setRide(response.data.ride);
                setSuccess("Wijzigingen opgeslagen!");
                setHasChanges(false);
                resetHistory();
                setTimeout(() => {
                    setSuccess(null);
                }, 5000);
            } catch(error) {
                console.error(error);
                Sentry.captureException(error);
                if(error.request.response.data.error) {
                    setGeoJSONError("Er ging iets fout. Probeer het later opnieuw. (" + error.request.response.data.error + ")");
                } else {
                    setGeoJSONError("Er ging iets fout. Probeer het later opnieuw.");
                }
            } finally {
                setSaving(false);
            }
        };
    }, [ride, organisationsContext, state.waypoints, state.markers, setRide]);

    return {
        undo,
        redo,
        canUndo,
        canRedo,
        ...state,
        hasChanges,
        geoJSONError,
        loading,
        saving,
        success,
        setWaypoints,
        setMarkers,
        save
    };
}

export default useRideEditorStateManager;
