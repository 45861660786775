import React from "react";

import DateTimeInput from "../../../../../components/DateTimeInput";

function EventForm({ event, setEvent, loading }) {
    if(!event) {
        return null;
    }
    return (
        <React.Fragment>
            <div className="card mb-3">
                <div className="card-body">
                    <h4 className="card-title">Algemeen</h4>
                    <div className="form-group">
                        <label htmlFor="name">Naam</label>
                        <input
                            type="text"
                            className="form-control"
                            id="name"
                            disabled={ loading }
                            value={ event.name }
                            onChange={ (event) => setEvent({ name: event.target.value }) }
                        />
                    </div>
                    <div className="form-group">
                        <label htmlFor="description">Beschrijving</label>
                        <textarea
                            className="form-control"
                            id="description"
                            rows={ 4 }
                            disabled={ loading }
                            value={ event.description }
                            onChange={ (event) => setEvent({ description: event.target.value }) }
                        />
                    </div>
                    <div className="form-group mb-0">
                        <label htmlFor="infoWebpage">Web pagina URL</label>
                        <input
                            type="text"
                            className="form-control"
                            id="infoWebpage"
                            disabled={ loading }
                            value={ event.infoWebpage }
                            onChange={ (event) => setEvent({ infoWebpage: event.target.value }) }
                            aria-describedby="infoWebpageHelp"
                            placeholder="https://"
                        />
                        <small id="infoWebpageHelp" className="form-text text-muted">
                            Vul hier een geldig webadres in waar meer info over het evenement te vinden is, inclusief https:// aan het begin.
                        </small>
                    </div>
                </div>
            </div>
            <div className="card mb-3">
                <div className="card-body">
                    <h4 className="card-title mb-0">
                        Datums
                    </h4>
                    <small id="datumsHelp" className="form-text text-muted mb-2">
                        Het invullen van de start- en einddatum is optioneel om het evenement aan te maken, maar is
                        vereist om het evenement te kunnen starten.
                    </small>
                    <DateTimeInput
                        date={ event.startDate }
                        onChange={ (date) => setEvent({ startDate: date }) }
                        loading={ loading }
                        id="start"
                        dateLabel="Start datum"
                        timeLabel="Start tijd"
                        className="mb-4"
                    />
                    <DateTimeInput
                        date={ event.endDate }
                        onChange={ (date) => setEvent({ endDate: date }) }
                        loading={ loading }
                        id="end"
                        dateLabel="Eind datum"
                        timeLabel="Eind tijd"
                    />
                </div>
            </div>
            <div className="card mb-3">
                <div className="card-body">
                    <h4 className="card-title">Chat</h4>
                    <div className="form-group mb-0">
                        <label htmlFor="chatAudioAutoplayMaxDistance">Maximale afstand voor automatisch afspelen van audio berichten (in meters)</label>
                        <input
                            type="number"
                            className="form-control"
                            id="chatAudioAutoplayMaxDistance"
                            disabled={ loading }
                            value={ event.chatAudioAutoplayMaxDistance }
                            onChange={ (event) => setEvent({ chatAudioAutoplayMaxDistance: event.target.value }) }
                        />
                        <small id="chatAudioAutoplayMaxDistanceHelp" className="form-text text-muted">
                            Om automatisch afspelen uit te schakelen, vul hier 0 in. Om altijd af te spelen, vul hier een heel groot getal in.
                        </small>
                    </div>
                </div>
            </div>
            <div className="card mb-3">
                <div className="card-body">
                    <h4 className="card-title">Zichtbaarheid</h4>
                    <div className="custom-control custom-checkbox">
                        <input
                            type="checkbox"
                            className="custom-control-input"
                            id="publiclyVisible"
                            disabled={ loading }
                            checked={ event.publiclyVisible }
                            onChange={ (event) => setEvent({ publiclyVisible: event.target.checked }) }
                        />
                        <label className="custom-control-label" htmlFor="publiclyVisible">
                            Zichtbaar voor iedereen
                        </label>
                        <small id="publiclyVisibleHelp" className="form-text text-muted">
                            Als deze optie niet is aangevinkt, is dit evenement alleen zichtbaar voor deelnemers.
                        </small>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

export default React.memo(EventForm);
