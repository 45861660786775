import React from "react";
import {
    Title
} from "@zandor300/backoffice-framework";

function Error404() {
    return (
        <Title preTitle="Error">404 Not Found</Title>
    );
}

export default React.memo(Error404);
