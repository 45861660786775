import React, {
    useContext
} from "react";
import {
    Dropdown
} from "react-bootstrap";

import MapboxStyleContext from "../../../../../../context/MapboxStyleContext";

function MapboxStyleDropdown({ className }) {
    const { style, setStyle, styles } = useContext(MapboxStyleContext);

    return (
        <Dropdown className={ className }>
            <Dropdown.Toggle variant="light" id="dropdown-basic">
                <i className={ style.icon + " fa-fw mr-2" }/>
                { style.name }
            </Dropdown.Toggle>

            <Dropdown.Menu>
                { styles.map((styleMap) => (
                    <Dropdown.Item key={ styleMap.id } onClick={ () => setStyle(styleMap.id) } active={ styleMap.id === style.id }>
                        <i className={ styleMap.icon + " fa-fw mr-2" }/>
                        { styleMap.name }
                    </Dropdown.Item>
                ))}
            </Dropdown.Menu>
        </Dropdown>
    );
}

export default React.memo(MapboxStyleDropdown);
