import React, {
    useEffect,
    useState
} from "react";
import axios from "axios";
import {
    Route,
    Switch,
    withRouter
} from "react-router-dom";
import {
    Alert
} from "react-bootstrap";
import {
    TabBar,
    TabBarItem,
    Title
} from "@zandor300/backoffice-framework";

import {
    withOrganisationsContext
} from "../../../../context/OrganisationsContext";
import {
    BackButton
} from "../../../../context/NavigationContext";
import Error404 from "../../Error404";
import Helmet from "../../../../components/Helmet";
import Loading from "../../../../components/Loading";

import RideDetailOverview from "./RideDetailOverview";
import RideDetailEdit from "./RideDetailEdit";

function RideDetail({ match, organisationsContext }) {
    const [ride, setRide] = useState(null);
    const [error, setError] = useState(null);
    const [errorCode, setErrorCode] = useState(null);

    useEffect(() => {
        if(!organisationsContext.currentOrganisation) {
            return;
        }
        axios.post("/getRide", {
            eventId: match.params.eventId,
            rideId: match.params.rideId,
            organisationId: organisationsContext.currentOrganisation.id
        })
            .then((response) => {
                if(response.data.valid) {
                    setRide(response.data.ride);
                } else {
                    setError("Er ging iets fout. Probeer het later opnieuw. (" + response.data.error + ")");
                    setErrorCode(response.data.error);
                }
            })
            .catch((error) => {
                console.error(error);
                setError("Er ging iets fout. Probeer het later opnieuw.");
            });
    }, [match.params.eventId, organisationsContext.currentOrganisation]);

    const baseUrl = match.url;

    if(errorCode === "DOESNT_EXIST") {
        return (
            <Error404/>
        );
    }
    const childProps = {
        ride,
        setRide
    };
    return (
        <React.Fragment>
            <Helmet title={ ride ? ride.name : "Rit" }/>
            <BackButton
                to={ `/events/${match.params.eventId}/rides` }
                title="Terug naar evenement"
            />
            { error ? (
                <Alert variant="danger">{ error }</Alert>
            ) : !ride ? (
                <Loading/>
            ) : (
                <React.Fragment>
                    <Title preTitle="Detail" noBottom>
                        { ride.name }
                    </Title>

                    <TabBar>
                        <TabBarItem to={ baseUrl }>
                            Overzicht
                        </TabBarItem>
                        <TabBarItem to={ `${baseUrl}/edit` }>
                            Bewerken
                        </TabBarItem>
                    </TabBar>

                    <Switch>
                        <Route path={ baseUrl } exact>
                            <RideDetailOverview { ...childProps }/>
                        </Route>
                        <Route path={ `${baseUrl}/edit` } exact>
                            <RideDetailEdit { ...childProps }/>
                        </Route>
                    </Switch>
                </React.Fragment>
            )}
        </React.Fragment>
    );
}

export default withRouter(withOrganisationsContext(React.memo(RideDetail)));
