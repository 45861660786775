import React from "react";
import {
    TabBar,
    TabBarItem
} from "@zandor300/backoffice-framework";

const modes = [
    { name: "Route", key: "route" },
    { name: "Markers", key: "markers" }
];

function RideEditModalModeSwitcher( { mode, setMode, disabled }) {
    return (
        <TabBar className="d-flex flex-row my-0 border-top-0">
            { modes.map((modeMap) => (
                <TabBarItem
                    className="w-50 text-center"
                    key={ modeMap.key }
                    to="#"
                    active={ modeMap.key === mode }
                    onClick={ (event) => {
                        event.preventDefault();
                        setMode(modeMap.key);
                    }}
                    disabled={ disabled }
                >
                    { modeMap.name }
                </TabBarItem>
            ))}
        </TabBar>
    );
}

export default React.memo(RideEditModalModeSwitcher);
