import React from "react";
import axios from "axios";
import {
    Alert,
    Button,
    Modal,
    Spinner
} from "react-bootstrap";

import Loading from "../../../../../components/Loading";

class ResendInviteModal extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            sending: false,
            error: null
        };
        this.onShow = this.onShow.bind(this);
        this.resendInvite = this.resendInvite.bind(this);
    }

    resendInvite() {
        this.setState({ sending: true, error: null });
        axios.post("/resendInvite", { inviteId: this.props.invite.id })
            .then((response) => {
                if(response.data.valid) {
                    this.props.handleSuccess();
                    this.props.handleClose();
                } else {
                    this.setState({ error: "Er ging iets fout. Probeer het later opnieuw. (" + response.data.error + ")" });
                }
            })
            .catch((error) => {
                console.error(error);
                this.setState({ error: "Er ging iets fout. Probeer het later opnieuw." });
            })
            .finally(() => {
                this.setState({ sending: false });
            });
    }

    onShow() {
        this.setState({ error: null });
    }

    render() {
        const {
            show,
            handleClose,
            invite
        } = this.props;
        const {
            sending,
            error
        } = this.state;
        return (
            <Modal show={ show } onHide={ handleClose } onShow={ this.onShow }>
                <Modal.Header closeButton>
                    <Modal.Title>Uitnodiging opnieuw versturen</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    { error && (
                        <Alert variant="danger">{ error }</Alert>
                    )}
                    { !invite ? (
                        <Loading/>
                    ) : (
                        <p className="mb-0">
                            Weet je zeker dat je de uitnodiging opnieuw wilt versturen aan <b>{ invite.email }</b>?
                        </p>
                    )}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={ handleClose } disabled={ sending }>
                        Sluiten
                    </Button>
                    <Button variant="primary" disabled={ sending } onClick={ this.resendInvite }>
                        { sending && (
                            <Spinner animation="border" variant="dark" size="sm" className="mr-2"/>
                        )}
                        Opnieuw versturen
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    }

}

export default ResendInviteModal;
