import React from "react";
import {
    Button,
    Modal
} from "react-bootstrap";

function CloseEditorUnsavedModal({ show, handleClose, onCloseUnsaved }) {
    return (
        <Modal show={ show } onHide={ handleClose } backdropClassName="waypoint-delete-backdrop">
            <Modal.Header closeButton>
                <Modal.Title>
                    Niet-opgeslagen wijzigingen
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p className="mb-0">
                    Er zijn wijzigingen die nog niet opgeslagen zijn. Weet je zeker dat je de editor wilt sluiten?
                </p>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={ handleClose }>
                    Annuleren
                </Button>
                <Button variant="danger" type="submit" onClick={ onCloseUnsaved }>
                    Afsluiten zonder opslaan
                </Button>
            </Modal.Footer>
        </Modal>
    );
}

export default React.memo(CloseEditorUnsavedModal);
