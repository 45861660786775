import React from "react";

import RideSidebarGeoJSONSummary from "../../../components/RideSidebarGeoJSONSummary";
import RideWaypointAddCard from "../../../components/RideWaypointAddCard";
import RideEditSidebarRouteWaypointsListSortable
    from "./RideEditSidebarRouteWaypointsListSortable";
import RideEditSidebarRouteEmptyState from "./RideEditSidebarRouteEmptyState";

function RideEditSidebarRoute({
    waypoints,
    editGeoJSON,
    onMouseEnter,
    onMouseLeave,
    handleShowDeleteWaypointModal,
    onWaypointRename,
    onWaypointsSorted,
    onClickAddMarker,
    onClickSearch
}) {
    return (
        <React.Fragment>
            { waypoints.length === 0 ? (
                <RideEditSidebarRouteEmptyState/>
            ) : (
                <RideSidebarGeoJSONSummary
                    geoJSON={ editGeoJSON }
                />
            )}
            <RideEditSidebarRouteWaypointsListSortable
                waypoints={ waypoints }
                setWaypoints={ onWaypointsSorted }
                onMouseEnter={ onMouseEnter }
                onMouseLeave={ onMouseLeave }
                onDelete={ handleShowDeleteWaypointModal }
                onWaypointRename={ onWaypointRename }
            />
            <div className="d-flex flex-row align-items-stretch">
                <RideWaypointAddCard
                    onClick={ onClickAddMarker }
                    className="flex-grow-1 mr-1 h-100"
                />
                <RideWaypointAddCard
                    icon="fa-search"
                    onClick={ onClickSearch }
                    className="flex-grow-1 ml-1 h-100"
                />
            </div>
        </React.Fragment>
    );
}

export default React.memo(RideEditSidebarRoute);
