import React from "react";
import axios from "axios";
import {
    Link,
    withRouter
} from "react-router-dom";
import {
    Alert,
    Button,
    OverlayTrigger,
    Tooltip
} from "react-bootstrap";

import {
    withOrganisationsContext
} from "../../../../context/OrganisationsContext";
import Loading from "../../../../components/Loading";
import DateFormatter from "../../../../components/DateFormatter";
import StartRideModal from "./modal/StartRideModal";
import EndRideModal from "./modal/EndRideModal";
import ArchiveRideModal from "./modal/ArchiveRideModal";
import ResetRideModal from "./modal/ResetRideModal";
import RideLiveLocationModal from "../../rides/detail/live-location/RideLiveLocationModal";

class EventDetailRides extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            rides: null,
            error: null,

            showArchiveRideModal: false,
            archiveRideModalRide: null,

            showStartRideModal: false,
            startRideModalRide: null,

            showEndRideModal: false,
            endRideModalRide: null,

            showResetRideModal: false,
            resetRideModalRide: null,

            showLiveLocationModal: false,
            liveLocationModalRide: null
        };
        this.onRideUpdated = this.onRideUpdated.bind(this);
    }

    componentDidMount() {
        if(this.props.event) {
            this.getRides();
        }
    }

    componentDidUpdate(prevProps, _prevState, _snapshot) {
        if(this.props.event && !prevProps.event) {
            this.getRides();
        }
    }

    getRides() {
        axios.post("/getRidesForEvent", { eventId: this.props.event.id })
            .then((response) => {
                if(response.data.valid) {
                    this.setState({ rides: response.data.rides });
                } else {
                    this.setState({ error: "Er ging iets fout. Probeer het later opnieuw. (" + response.data.error + ")" });
                }
            })
            .catch(() => {
                this.setState({ error: "Er ging iets fout. Probeer het later opnieuw." });
            });
    }

    onRideUpdated(ride) {
        this.setState((prevState) => {
            const rides = prevState.rides.map((prevRide) => {
                if(prevRide.id === ride.id) {
                    return ride;
                }
                return prevRide;
            }).sort((ride1, ride2) => {
                if(ride1.archived && !ride2.archived) { return 1; }
                if(!ride1.archived && ride2.archived) { return -1; }
                return 0;
            });
            return { rides };
        });
    }

    onClickStartRide(ride) {
        this.setState({ showStartRideModal: true, startRideModalRide: ride });
    }

    onClickEndRide(ride) {
        this.setState({ showEndRideModal: true, endRideModalRide: ride });
    }

    onClickResetRide(ride) {
        this.setState({ showResetRideModal: true, resetRideModalRide: ride });
    }

    onClickArchiveRide(ride) {
        this.setState({ showArchiveRideModal: true, archiveRideModalRide: ride });
    }

    onClickShowLiveLocation(ride) {
        this.setState({ showLiveLocationModal: true, liveLocationModalRide: ride });
    }

    render() {
        const currentOrganisation = this.props.organisationsContext.currentOrganisation;
        if(!currentOrganisation) {
            return null;
        }
        const {
            baseUrl
        } = this.props;
        const {
            showArchiveRideModal,
            archiveRideModalRide,
            showStartRideModal,
            startRideModalRide,
            showEndRideModal,
            endRideModalRide,
            showResetRideModal,
            resetRideModalRide,

            rides,
            error
        } = this.state;
        return (
            <React.Fragment>
                <ArchiveRideModal
                    show={ showArchiveRideModal }
                    ride={ archiveRideModalRide }
                    handleClose={ () => this.setState({ showArchiveRideModal: false }) }
                    rideUpdated={ this.onRideUpdated }
                />
                <StartRideModal
                    show={ showStartRideModal }
                    ride={ startRideModalRide }
                    handleClose={ () => this.setState({ showStartRideModal: false }) }
                    rideUpdated={ this.onRideUpdated }
                />
                <EndRideModal
                    show={ showEndRideModal }
                    ride={ endRideModalRide }
                    handleClose={ () => this.setState({ showEndRideModal: false }) }
                    rideUpdated={ this.onRideUpdated }
                />
                <ResetRideModal
                    show={ showResetRideModal }
                    ride={ resetRideModalRide }
                    handleClose={ () => this.setState({ showResetRideModal: false }) }
                    rideUpdated={ this.onRideUpdated }
                />
                <RideLiveLocationModal
                    show={ this.state.showLiveLocationModal }
                    ride={ this.state.liveLocationModalRide }
                    handleClose={ () => this.setState({ showLiveLocationModal: false }) }
                />

                <Link
                    to={ baseUrl + "/rides/add" }
                    className="btn btn-primary float-right mb-3"
                >
                    <i className="fas fa-plus mr-2"/>
                    Nieuwe rit aanmaken
                </Link>

                <table className="table table-hover table-sm">
                    <thead className="thead-light">
                        <tr className="tr-sticky">
                            <th scope="col" style={{textAlign: "left"}}>Name</th>
                            <th scope="col" style={{textAlign: "left"}}>Start</th>
                            <th scope="col" style={{textAlign: "left"}}>Einde</th>
                            <th scope="col"/>
                        </tr>
                    </thead>
                    <tbody>
                        { error ? (
                            <tr>
                                <td colSpan="4">
                                    <Alert variant="danger">{ error }</Alert>
                                </td>
                            </tr>
                        ) : !rides ? (
                            <tr>
                                <td colSpan="4">
                                    <Loading/>
                                </td>
                            </tr>
                        ) : rides.length === 0 ? (
                            <tr>
                                <td colSpan="4">
                                    Er zijn geen ritten voor dit evenement.
                                </td>
                            </tr>
                        ) : rides.map((ride) => {
                            const url = baseUrl + "/rides/" + ride.id;
                            return (
                                <tr key={ ride.id } className={ "table-clickable-row" + (ride.archived ? " text-muted" : "") }>
                                    <td className="align-middle">
                                        <Link to={ url }>
                                            { ride.archived && (
                                                <OverlayTrigger overlay={
                                                    <Tooltip id="tooltip-ride-archive">
                                                        Rit is gearchiveerd en dus niet zichtbaar voor deelnemers.
                                                    </Tooltip>
                                                }>
                                                    <i className="fa-solid fa-eye-slash mr-2"/>
                                                </OverlayTrigger>
                                            )}
                                            { ride.name }
                                        </Link>
                                    </td>
                                    <td className="align-middle">
                                        <Link to={ url }>
                                            { ride.startDate ? (
                                                <DateFormatter date={ ride.startDate } format="DD-MM-YYYY HH:mm"/>
                                            ) : (
                                                <i className="text-muted">Niet ingesteld</i>
                                            )}
                                        </Link>
                                    </td>
                                    <td className="align-middle">
                                        <Link to={ url }>
                                            { ride.endDate ? (
                                                <DateFormatter date={ ride.endDate } format="DD-MM-YYYY HH:mm"/>
                                            ) : (
                                                <i className="text-muted">Niet ingesteld</i>
                                            )}
                                        </Link>
                                    </td>
                                    <td className="align-middle text-right">
                                        { !ride.archived && (
                                            <React.Fragment>
                                                { !ride.started ? (
                                                    <Button
                                                        variant="primary"
                                                        size="sm"
                                                        onClick={ () => this.onClickStartRide(ride) }
                                                    >
                                                        <i className="fas fa-play mr-2"/>
                                                        Rit starten
                                                    </Button>
                                                ) : !ride.ended ? (
                                                    <React.Fragment>
                                                        <Button
                                                            variant="secondary"
                                                            size="sm"
                                                            onClick={ () => this.onClickShowLiveLocation(ride) }
                                                            className="mr-2"
                                                        >
                                                            <i className="fa-solid fa-location-arrow mr-2"/>
                                                            Live locaties
                                                        </Button>
                                                        <Button
                                                            variant="primary"
                                                            size="sm"
                                                            onClick={ () => this.onClickEndRide(ride) }
                                                        >
                                                            <i className="fas fa-stop mr-2"/>
                                                            Rit beëindigen
                                                        </Button>
                                                    </React.Fragment>
                                                ) : (
                                                    <div className="d-flex flex-row align-items-center justify-content-end">
                                                        <div className="mr-2 text-success">
                                                            <i className="fas fa-check-circle mr-2"/>
                                                            Rit beëindigd
                                                        </div>
                                                        <Button
                                                            variant="primary"
                                                            size="sm"
                                                            onClick={ () => this.onClickResetRide(ride) }
                                                        >
                                                            <i className="fa-solid fa-arrow-rotate-left mr-2"/>
                                                            Rit resetten
                                                        </Button>
                                                    </div>
                                                )}
                                            </React.Fragment>
                                        )}
                                        { !ride.started && (
                                            <OverlayTrigger overlay={
                                                <Tooltip id="tooltip-ride-archive">
                                                    { ride.archived ? "Rit herstellen" : "Rit archiveren" }
                                                </Tooltip>
                                            }>
                                                <Button
                                                    variant="secondary"
                                                    size="sm"
                                                    className="ml-2"
                                                    onClick={ () => this.onClickArchiveRide(ride) }
                                                >
                                                    { ride.archived ? (
                                                        <i className="fa-solid fa-trash-slash fa-fw"/>
                                                    ) : (
                                                        <i className="fa-solid fa-trash fa-fw"/>
                                                    )}
                                                </Button>
                                            </OverlayTrigger>
                                        )}
                                    </td>
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            </React.Fragment>
        );
    }
}

export default withOrganisationsContext(withRouter(EventDetailRides));
