import React from "react";
import axios from "axios";
import {
    Alert,
    Button,
    Modal
} from "react-bootstrap";

import Loading from "../../../../../components/Loading";

class EndRideModal extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            error: null
        };
        this.onEndRide = this.onEndRide.bind(this);
    }

    onEndRide() {
        this.setState({ loading: true, error: null });
        axios.post("/endRide", { rideId: this.props.ride.id })
            .then((response) => {
                if(response.data.valid) {
                    this.props.rideUpdated(response.data.ride);
                    this.props.handleClose();
                } else {
                    this.setState({ error: "Er ging iets fout. Probeer het later opnieuw. (" + response.data.error + ")" });
                }
            })
            .catch(() => {
                this.setState({ error: "Er ging iets fout. Probeer het later opnieuw." });
            })
            .finally(() => {
                this.setState({ loading: false });
            });
    }

    render() {
        return (
            <Modal show={ this.props.show } onHide={ this.props.handleClose }>
                <Modal.Header closeButton>
                    <Modal.Title>Rit beëindigen</Modal.Title>
                </Modal.Header>
                { this.state.loading ? (
                    <Modal.Body>
                        <Loading/>
                    </Modal.Body>
                ) : (
                    <React.Fragment>
                        <Modal.Body>
                            { this.state.error && (
                                <Alert variant="danger">{ this.state.error }</Alert>
                            )}
                            <p>
                                Locatie delen bij elke deelnemer zal worden beëindigd.
                            </p>
                            <p className="mb-0">
                                Weet je zeker dat je de rit wilt beëindigen? Dit is niet terug te draaien.
                            </p>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={ this.props.handleClose }>
                                Sluiten
                            </Button>
                            <Button variant="primary" type="submit" onClick={ this.onEndRide }>
                                Rit beëindigen
                            </Button>
                        </Modal.Footer>
                    </React.Fragment>
                )}
            </Modal>
        );
    }
}

export default EndRideModal;
