import React, {
    useMemo
} from "react";
import moment from "moment";

function RideForm({ ride, setRide, loading }) {
    if(!ride) {
        return null;
    }

    const updateDate = useMemo(() => {
        return (event, property, type, ride) => {
            const currentDateTime = ride[property];
            const dateSplit = currentDateTime.split(" ");
            let currentDate, currentTime;
            if(dateSplit.length === 2) {
                currentDate = dateSplit[0];
                currentTime = dateSplit[1];
            } else {
                currentDate = moment().format("YYYY-MM-DD");
                currentTime = "00:00:00";
            }
            if(type === "date") {
                currentDate = event.target.value;
            } else if(type === "time") {
                currentTime = event.target.value;
            }
            let changes = {};
            changes[property] = `${currentDate} ${currentTime}`;
            setRide(changes);
        };
    }, [setRide]);

    return (
        <React.Fragment>
            <div className="card mb-3">
                <div className="card-body">
                    <h4 className="card-title">Algemeen</h4>
                    <div className="form-group">
                        <label htmlFor="name">Naam</label>
                        <input
                            type="text"
                            className="form-control"
                            id="name"
                            disabled={ loading }
                            value={ ride.name }
                            onChange={ (event) => setRide({ name: event.target.value }) }
                        />
                    </div>
                </div>
            </div>
            <div className="card mb-3">
                <div className="card-body">
                    <h4 className="card-title">Datums</h4>
                    <div className="row mb-4">
                        <div className="col-md-6">
                            <div className="form-group mb-md-0">
                                <label htmlFor="startDate">Start datum</label>
                                <input
                                    type="date"
                                    className="form-control"
                                    id="startDate"
                                    disabled={ loading }
                                    value={ ride.startDate === null ? "" : ride.startDate.split(" ")[0] }
                                    onChange={ (event) => { updateDate(event, "startDate", "date", ride); }}
                                />
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-group mb-0">
                                <label htmlFor="startDate">Start tijd</label>
                                <input
                                    type="time"
                                    className="form-control"
                                    id="startDate"
                                    disabled={ loading }
                                    value={ ride.startDate === null ? "" : ride.startDate.split(" ")[1] }
                                    onChange={ (event) => { updateDate(event, "startDate", "time", ride); }}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6">
                            <div className="form-group mb-md-0">
                                <label htmlFor="endDate">Eind datum</label>
                                <input
                                    type="date"
                                    className="form-control"
                                    id="endDate"
                                    disabled={ loading }
                                    value={ ride.endDate === null ? "" : ride.endDate.split(" ")[0] }
                                    onChange={ (event) => { updateDate(event, "endDate", "date", ride); }}
                                />
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-group mb-0">
                                <label htmlFor="endDate">Eind tijd</label>
                                <input
                                    type="time"
                                    className="form-control"
                                    id="endDate"
                                    disabled={ loading }
                                    value={ ride.endDate === null ? "" : ride.endDate.split(" ")[1] }
                                    onChange={ (event) => { updateDate(event, "endDate", "time", ride); }}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="card mb-3">
                <div className="card-body">
                    <h4 className="card-title">Zichtbaarheid</h4>
                    <div className="custom-control custom-checkbox">
                        <input
                            type="checkbox"
                            className="custom-control-input"
                            id="onlyVisibleWhenStarted"
                            checked={ride.onlyVisibleWhenStarted}
                            onChange={(event) => setRide({onlyVisibleWhenStarted: event.target.checked})}
                        />
                        <label className="custom-control-label" htmlFor="onlyVisibleWhenStarted">
                            Alleen zichtbaar voor deelnemers indien gestart
                        </label>
                    </div>
                </div>
            </div>
            <div className="card mb-3">
                <div className="card-body">
                    <h4 className="card-title">Automatisering</h4>
                    <div className="custom-control custom-checkbox">
                        <input
                            type="checkbox"
                            className="custom-control-input"
                            id="autoStart"
                            checked={ ride.autoStart }
                            onChange={ (event) => setRide({ autoStart: event.target.checked }) }
                        />
                        <label className="custom-control-label" htmlFor="autoStart">
                            Automatisch starten op startdatum
                        </label>
                    </div>
                    <div className="custom-control custom-checkbox">
                        <input
                            type="checkbox"
                            className="custom-control-input"
                            id="autoStop"
                            checked={ ride.autoStop }
                            onChange={ (event) => setRide({ autoStop: event.target.checked }) }
                        />
                        <label className="custom-control-label" htmlFor="autoStop">
                            Automatisch stoppen op einddatum
                        </label>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

export default React.memo(RideForm);
