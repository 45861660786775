import {
    useEffect
} from "react";

function onHistoryChange(history, callback) {
    useEffect(() => {
        const unlisten = history.listen(() => {
            callback();
        });
        return () => {
            unlisten();
        };
    }, [history, callback]);

    return null;
}

export default onHistoryChange;
