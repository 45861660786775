import React from "react";
import axios from "axios";
import {
    Alert,
    Button,
    Modal
} from "react-bootstrap";

import Loading from "../../../../../components/Loading";

class RemoveInviteModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            updating: false,
            error: null
        };
    }

    removeParticipant() {
        this.setState({ updating: true, error: null });
        axios.post("/removeEventParticipant", { eventId: this.props.event.id, participantId: this.props.participant.id })
            .then((response) => {
                this.setState({ updating: false });
                if(response.data.valid) {
                    this.props.handleSuccess();
                    this.closeModal();
                } else {
                    this.setState({ updating: false, error: "Er ging iets fout. Probeer het later opnieuw." });
                }
            })
            .catch(() => {
                this.setState({ updating: false, error: "Er ging iets fout. Probeer het later opnieuw." });
            });
    }

    closeModal() {
        this.props.handleClose();
    }

    render() {
        return (
            <Modal show={this.props.show} onHide={ () => this.closeModal() }>
                <Modal.Header closeButton>
                    <Modal.Title>Deelnemer loskoppelen</Modal.Title>
                </Modal.Header>
                { this.state.updating || this.props.event == null || this.props.participant == null ? (
                    <Modal.Body>
                        <Loading/>
                    </Modal.Body>
                ) : (
                    <React.Fragment>
                        <Modal.Body>
                            { this.state.error && (
                                <Alert variant="danger">{ this.state.error }</Alert>
                            )}
                            <p>
                                De deelnemer zal losgekoppeld worden en zal een nieuwe uitnodiging nodig hebben om weer
                                deel te kunnen nemen. Deze actie kan niet teruggedraait worden.
                            </p>
                            <p className="mb-0">
                                Je gaat de volgende deelnemer loskoppelen:<br/>
                                <b>{ this.props.participant.name }</b> ({ this.props.participant.email })
                            </p>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={ () => this.closeModal() }>
                                Annuleren
                            </Button>
                            <Button variant="danger" onClick={ () => this.removeParticipant() }>
                                Deelnemer loskoppelen
                            </Button>
                        </Modal.Footer>
                    </React.Fragment>
                )}
            </Modal>
        );
    }

}

export default RemoveInviteModal;
