import React from "react";
import axios from "axios";
import {
    Alert,
    Button,
    Modal,
    Spinner
} from "react-bootstrap";

class ArchiveRideModal extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            error: null
        };
        this.onSubmit = this.onSubmit.bind(this);
    }

    onSubmit() {
        const {
            ride
        } = this.props;
        this.setState({ loading: true, error: null });
        axios.post("/setRideArchived", {
            rideId: ride.id,
            archived: (!ride.archived) ? 1 : 0
        })
            .then((response) => {
                if(response.data.valid) {
                    this.props.rideUpdated(response.data.ride);
                    this.props.handleClose();
                } else {
                    this.setState({ error: "Er ging iets fout. Probeer het later opnieuw. (" + response.data.error + ")" });
                }
            })
            .catch(() => {
                this.setState({ error: "Er ging iets fout. Probeer het later opnieuw." });
            })
            .finally(() => {
                this.setState({ loading: false });
            });
    }

    render() {
        const {
            loading,
            error
        } = this.state;
        const {
            show,
            handleClose,
            ride
        } = this.props;
        return (
            <Modal show={ show } onHide={ handleClose }>
                <Modal.Header closeButton>
                    <Modal.Title>
                        { ride && ride.archived ? (
                            "Rit herstellen"
                        ) : (
                            "Rit archiveren"
                        )}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    { error && (
                        <Alert variant="danger">{ error }</Alert>
                    )}
                    <p className="mb-0">
                        { ride && ride.archived ? (
                            "De rit zal weer zichtbaar worden voor deelnemers."
                        ) : (
                            "De rit zal worden verborgen voor deelnemers. De rit zal nog wel zichtbaar zijn voor beheerders."
                        )}
                    </p>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={ handleClose } disabled={ loading }>
                        Annuleer
                    </Button>
                    <Button variant="primary" type="submit" onClick={ this.onSubmit } disabled={ loading }>
                        { loading && (
                            <Spinner animation="border" variant="dark" size="sm" className="mr-2"/>
                        )}
                        Rit { ride && ride.archived ? "herstellen" : "archiveren" }
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    }
}

export default ArchiveRideModal;
